import React from "react";
import styled, { css } from "styled-components";
import familyTreeRainbow from "../images/family-tree-rainbow.svg";
import Heading from "../components/Heading";
import Text from "../components/Text";
import { postMessage } from "../utils/postmessage";

const Container = styled.div`
  width: 100vw;
  height: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${familyTreeRainbow});
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: 50% 55%;
  position: relative;
`;

const StyledHeading = styled(Heading.H1)`
  position: absolute;
  bottom: 10vw;
  line-height: 30vw;
  z-index: 500;
`;

const FamilyItem = styled.div`
  position: absolute;
  width: ${(props) => (props.width ? `${props.width}vw` : "15vw")};
  height: 10vw;
  background-color: rgba(0, 0, 0, 0);
  transform-origin: 100% 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  ${(props) =>
    props.left &&
    css`
      left: ${props.left}vw;
    `}
  ${(props) =>
    props.right &&
    css`
      right: ${props.right}vw;
    `}
  ${(props) =>
    props.top &&
    css`
      top: ${props.top}vw;
    `}
  ${(props) =>
    props.bottom &&
    css`
      bottom: ${props.bottom}vw;
    `}
  ${(props) =>
    props.rotate &&
    css`
      transform: rotate(${props.rotate}deg);
    `}
`;

const FamilyTree = (props) => {
  const { customQuestions } = props;
  const { items } = customQuestions;
  items.sort((a, b) => (a.position > b.position ? 1 : -1));
  return (
    <Container>
      <FamilyItem rotate={-75} bottom={32} left={-7} onClick={() => postMessage(items?.[0])}>
        <Text tac>{items?.[0]?.answers?.items?.[0]?.text || items?.[0]?.placeholder}</Text>
      </FamilyItem>
      <FamilyItem rotate={-60} bottom={48} left={1} onClick={() => postMessage(items?.[1])}>
        <Text tac>{items?.[1]?.answers?.items?.[0]?.text || items?.[1]?.placeholder}</Text>
      </FamilyItem>
      <FamilyItem rotate={-40} top={30} left={14} onClick={() => postMessage(items?.[2])}>
        <Text tac>{items?.[2]?.answers?.items?.[0]?.text || items?.[2]?.placeholder}</Text>
      </FamilyItem>
      <FamilyItem rotate={-15} top={24} left={31} onClick={() => postMessage(items?.[3])}>
        <Text tac>{items?.[3]?.answers?.items?.[0]?.text || items?.[3]?.placeholder}</Text>
      </FamilyItem>
      <FamilyItem rotate={75} bottom={18} right={5} onClick={() => postMessage(items?.[4])}>
        <Text tac>{items?.[4]?.answers?.items?.[0]?.text || items?.[4]?.placeholder}</Text>
      </FamilyItem>
      <FamilyItem
        width={14}
        rotate={60}
        bottom={35}
        right={10}
        onClick={() => postMessage(items?.[5])}
      >
        <Text tac>{items?.[5]?.answers?.items?.[0]?.text || items?.[5]?.placeholder}</Text>
      </FamilyItem>
      <FamilyItem rotate={40} top={39.5} right={19} onClick={() => postMessage(items?.[6])}>
        <Text tac>{items?.[6]?.answers?.items?.[0]?.text || items?.[6]?.placeholder}</Text>
      </FamilyItem>
      <FamilyItem rotate={15} top={28} right={34} onClick={() => postMessage(items?.[7])}>
        <Text tac>{items?.[7]?.answers?.items?.[0]?.text || items?.[7]?.placeholder}</Text>
      </FamilyItem>
      <FamilyItem rotate={-70} bottom={37} left={4.5} onClick={() => postMessage(items?.[8])}>
        <Text tac>{items?.[8]?.answers?.items?.[0]?.text || items?.[8]?.placeholder}</Text>
      </FamilyItem>
      <FamilyItem rotate={-25} top={36} left={27} onClick={() => postMessage(items?.[9])}>
        <Text tac>{items?.[9]?.answers?.items?.[0]?.text || items?.[9]?.placeholder}</Text>
      </FamilyItem>
      <FamilyItem rotate={65} bottom={23} right={17} onClick={() => postMessage(items?.[10])}>
        <Text tac>{items?.[10]?.answers?.items?.[0]?.text || items?.[10]?.placeholder}</Text>
      </FamilyItem>
      <FamilyItem rotate={25} top={43} right={32} onClick={() => postMessage(items?.[11])}>
        <Text tac>{items?.[11]?.answers?.items?.[0]?.text || items?.[11]?.placeholder}</Text>
      </FamilyItem>
      <FamilyItem rotate={50} bottom={29} right={30} onClick={() => postMessage(items?.[12])}>
        <Text tac>{items?.[12]?.answers?.items?.[0]?.text || items?.[12]?.placeholder}</Text>
      </FamilyItem>
      <FamilyItem rotate={-50} bottom={40.5} left={22} onClick={() => postMessage(items?.[13])}>
        <Text tac>{items?.[13]?.answers?.items?.[0]?.text || items?.[13]?.placeholder}</Text>
      </FamilyItem>
      <FamilyItem bottom={30} left={41.5} onClick={() => postMessage(items?.[14])}>
        <Text tac>{items?.[14]?.answers?.items?.[0]?.text || items?.[14]?.placeholder}</Text>
      </FamilyItem>
      <StyledHeading tac onClick={() => postMessage(items?.[15])}>
        {items?.[15]?.answers?.items?.[0]?.text || items?.[15]?.placeholder}
      </StyledHeading>
    </Container>
  );
};

export default FamilyTree;
