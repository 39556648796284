import React, { useCallback } from "react";
import styled from "styled-components";
import { useOverflowDetector } from "react-detectable-overflow";
import Photo from "../components/Photo";
import Text from "../components/Text";
import Heading from "../components/Heading";
import { postMessage } from "../utils/postmessage";

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 90vw;
  height: 90vw;
  justify-content: center;
  align-items: center;
`;

const StyledPhoto = styled(Photo)`
  width: 52.5vw;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.mb &&
    `
    margin-bottom: ${props.mb}vw;
    `}
`;

const Subitles = styled.div``;

const SubitlesContainer = styled.div`
  width: 35vw;
  margin-left: 2.5vw;
  height: calc(52.5vw * (2 / 3));
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: -2.5vw;
`;

const SubtitleItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2.5vw;
`;

const StyledText = styled(Text)`
  flex-grow: 1;
  margin-left: 0.7vw;
  white-space: nowrap;
`;

const StyledHeading = styled(Heading.H4)`
  white-space: nowrap;
  width: auto;
`;

const OffsetPhotosSubtitles = ({ customQuestions, handleOverflow = () => null }) => {
  const { items } = customQuestions;
  items.sort((a, b) => (a.position > b.position ? 1 : -1));

  const { ref } = useOverflowDetector({
    onChange: useCallback(
      (overflow) => {
        handleOverflow(overflow);
      },
      [handleOverflow]
    )
  });
  return (
    <OuterContainer ref={ref}>
      <Row mb={2.5}>
        <Heading.H3 tac onClick={() => postMessage(items?.[0])}>
          {items?.[0]?.answers?.items?.[0]?.text || items?.[0]?.placeholder}
        </Heading.H3>
      </Row>
      <Row mb={2.5}>
        <StyledPhoto
          imgObject={items[1]?.answers?.items?.[0]?.image || null}
          loading={items[1]?.answers?.items?.[0]?.image?.loading || null}
          ratio={items[1]?.ratio}
          onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
            postMessage({
              ...items[1],
              recommendedWidthPercentage,
              recommendedHeightPercentage
            })
          }
        />
        <SubitlesContainer>
          <Subitles>
            {items
              .filter((item) => item.type === "subtitle")
              .map((question, index, array) => (
                <React.Fragment key={index}>
                  {question?.type === "subtitle" && (
                    <SubtitleItem>
                      <StyledHeading onClick={() => postMessage(question)}>
                        {question?.answers?.items?.[0]?.text || question?.placeholder}
                      </StyledHeading>
                      <StyledText
                        subtitle
                        onClick={() => postMessage(items[question?.position + 1])}
                      >
                        {items[question?.position + 1]?.answers?.items?.[0]?.text}
                      </StyledText>
                    </SubtitleItem>
                  )}
                </React.Fragment>
              ))}
          </Subitles>
        </SubitlesContainer>
      </Row>
      <Row>
        <StyledPhoto
          imgObject={items[10]?.answers?.items?.[0]?.image || null}
          loading={items[10]?.answers?.items?.[0]?.image?.loading || null}
          ratio={items[10]?.ratio}
          onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
            postMessage({
              ...items[10],
              recommendedWidthPercentage,
              recommendedHeightPercentage
            })
          }
        />
      </Row>
    </OuterContainer>
  );
};

export default OffsetPhotosSubtitles;
