import React from "react";
import styled, { css } from "styled-components";

const baseStyles = css`
  font-family: "La Ronda", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  margin-bottom: 0;
  margin-top: 0;
  white-space: pre-wrap;
  overflow: visible;
`;

const Container = styled.div`
  width: 100%;
  display: table;
  table-layout: fixed;
  overflow: hidden;
  ${(props) =>
    props.tac &&
    `
    text-align: center;
    `}
  ${(props) =>
    props.tal &&
    `
      text-align: left;
      `}
  ${(props) =>
    props.tar &&
    `
      text-align: right;
      `}
  ${(props) =>
    props.mb &&
    `
      margin-bottom: ${props.mb}vw;
    `}
  ${(props) =>
    props.mt &&
    `
      margin-top: ${props.mt}vw;
    `}
`;

const StyledH1 = styled.h1`
  ${baseStyles}
  font-size: 14vw;
  line-height: 23vw;
  letter-spacing: 0.5vw;
  white-space: nowrap;
  ${(props) => props.lh && `line-height:${props.lh}vw;`}
  ${(props) => props.white && `color: white;`}
`;

const StyledH2 = styled.h2`
  ${baseStyles}
  font-size: 10vw;
  line-height: 17vw;
  letter-spacing: 0.35vw;
  white-space: nowrap;
  ${(props) => props.lh && `line-height:${props.lh}vw;`}
  ${(props) => props.white && `color: white;`}
`;

const StyledH3 = styled.h3`
  ${baseStyles}
  font-size: 7vw;
  line-height: 13vw;
  letter-spacing: 0.35vw;
  white-space: nowrap;
  ${(props) => props.lh && `line-height:${props.lh}vw;`}
  ${(props) => props.white && `color: white;`}
`;

const StyledH4 = styled.h4`
  ${baseStyles}
  font-family: "Quicksand", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 100;
  text-transform: uppercase;
  font-size: 1.75vw;
  line-height: 2.1vw;
  ${(props) =>
    !props.children &&
    `
      width:100%;
      min-height:3vw;
      line-height: 3vw;
      border-bottom: 0.2vw solid black;
      box-sizing: border-box;

    `}
  ${(props) =>
    props.bold &&
    `
    font-weight:600;
  `}
  ${(props) => props.white && `color: white;`}
`;

const StyledH5 = styled.h5`
  ${baseStyles}
  font-size: 3vw;
`;

const StyledH6 = styled.h6`
  ${baseStyles}
  font-size: 3vw;
`;

const replaceGlyphs = (string) => {
  return string
    ?.replace(/A/g, "")
    ?.replace(/H/g, "")
    ?.replace(/T/g, "")
    ?.replace(/’/g, "'")
    ?.replace(/…/g, "...")
    ?.replace(/♥️/g, "❤️")
    ?.replace(/\uFFFC/g, "");
};

const OutputH1 = ({ children, className, tac, tal, tar, mb, mt, onClick, lh, white }) => (
  <Container
    className={className}
    tac={tac}
    tal={tal}
    tar={tar}
    mb={mb}
    mt={mt}
    onClick={onClick}
    key={children}
  >
    <StyledH1 white={white} lh={lh}>
      {replaceGlyphs(children)}
    </StyledH1>
  </Container>
);
const OutputH2 = ({ children, className, tac, tal, tar, mb, mt, onClick, lh, white }) => (
  <Container
    className={className}
    tac={tac}
    tal={tal}
    tar={tar}
    mb={mb}
    mt={mt}
    onClick={onClick}
    key={children}
  >
    <StyledH2 white={white} lh={lh}>
      {replaceGlyphs(children)}
    </StyledH2>
  </Container>
);
const OutputH3 = ({ children, className, tac, tal, tar, mb, mt, onClick, lh, white }) => (
  <Container
    className={className}
    tac={tac}
    tal={tal}
    tar={tar}
    mb={mb}
    mt={mt}
    onClick={onClick}
    key={children}
  >
    <StyledH3 white={white} lh={lh}>
      {replaceGlyphs(children)}
    </StyledH3>
  </Container>
);
const OutputH4 = ({ children, className, tac, tal, tar, mb, mt, onClick, lh, white }) => (
  <Container
    className={className}
    tac={tac}
    tal={tal}
    tar={tar}
    mb={mb}
    mt={mt}
    onClick={onClick}
    key={children}
  >
    <StyledH4 white={white} lh={lh}>
      {children}
    </StyledH4>
  </Container>
);

const Heading = {
  H1: OutputH1,
  H2: OutputH2,
  H3: OutputH3,
  H4: OutputH4,
  H5: StyledH5,
  H6: StyledH6,
  baseStyles
};

export default Heading;
