import React, { useCallback } from "react";
import { useOverflowDetector } from "react-detectable-overflow";
import { postMessage } from "../utils/postmessage";
import styled from "styled-components";
import Heading from "../components/Heading";
import Photo from "../components/Photo";

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 90vw;
  height: 90vw;
`;
const HeaderContainer = styled.div`
  order: ${(props) => props.position || 0};
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  width: 100%;
  margin-bottom: 5vw;
  h2 {
    width: 100%;
    text-align: center;
  }
`;
const StyledHeading = styled(Heading.H2)``;
const StyledPhoto = styled(Photo)`
  order: ${(props) => props.position || 0};
  height: calc(90vw * (2 / 3));
`;

const FrontPage = ({ customQuestions, handleOverflow = () => null }) => {
  const { ref } = useOverflowDetector({
    onChange: useCallback(
      (overflow) => {
        handleOverflow(overflow);
      },
      [handleOverflow]
    )
  });
  const { items } = customQuestions;
  items.sort((a, b) => (a.position > b.position ? 1 : -1));
  return (
    <OuterContainer ref={ref}>
      {customQuestions.items.map((question, index) => (
        <React.Fragment key={index}>
          {question?.type === "title" && (
            <HeaderContainer
              key={question?.id}
              position={question?.position}
              onClick={() => postMessage(question)}
            >
              <StyledHeading>
                {question?.answers?.items?.[0]?.text || question?.placeholder}
              </StyledHeading>
            </HeaderContainer>
          )}
          {question?.type === "image" && (
            <StyledPhoto
              key={question?.id}
              position={question?.position}
              imgObject={question?.answers?.items?.[0]?.image || null}
              loading={question?.answers?.items?.[0]?.image?.loading || null}
              ratio={question?.ratio}
              onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
                postMessage({
                  ...question,
                  recommendedWidthPercentage,
                  recommendedHeightPercentage
                })
              }
            />
          )}
        </React.Fragment>
      ))}
    </OuterContainer>
  );
};

export default FrontPage;
