import React, { useCallback, useState, useEffect } from "react";
import { useOverflowDetector } from "react-detectable-overflow";
import { postMessage } from "../utils/postmessage";
import styled, { css } from "styled-components";
import Heading from "../components/Heading";
import Photo from "../components/Photo";
import Text from "../components/Text";

const OuterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 90vw;
  max-height: 90vw;
  margin-bottom: -2.5vw;
`;
const InnerContainer = styled.div`
  order: ${(props) => props.position || 0};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${(props) =>
    !props.title &&
    css`
      width: calc((90vw - (${(props) => props.columns - 1} * 2.5vw)) / ${(props) => props.columns});
    `};
  ${(props) =>
    props.title &&
    css`
      width: calc(
        ((90vw - (${(props) => props.columns - 1} * 2.5vw)) / ${(props) => props.columns}) * 2 +
          2.5vw
      );
    `}
  height: calc(
    (90vw - (${(props) => props.columns - 1} * 2.5vw)) / ${(props) => props.columns}
  );

  ${(props) =>
    props.backgroundColor &&
    `
    background-color:${props.backgroundColor}`}
  margin-bottom: 2.5vw;
`;

const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const GridTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 5vw);
  height: 100%;
`;

const GridText = styled(Text)`
  width: 70%;
  white-space: pre-wrap;
`;

const TextComponent = ({ question, array, index, handleOverflow, columns }) => {
  const { ref } = useOverflowDetector({
    onChange: useCallback(
      (overflow) => {
        handleOverflow(overflow);
      },
      [handleOverflow]
    )
  });
  return (
    <InnerContainer
      key={question?.position}
      position={question?.position}
      columns={columns}
      backgroundColor={question?.backgroundColor}
      ref={ref}
    >
      <GridItem>
        <GridTextContainer>
          <Heading.H4 tac mb={2.5} onClick={() => postMessage(question)}>
            {question?.answers?.items?.[0]?.text || question?.placeholder}
          </Heading.H4>
          <GridText tac onClick={() => postMessage(array?.[index + 1])}>
            {array?.[index + 1]?.answers?.items?.[0]?.text || array?.[index + 1]?.placeholder}
          </GridText>
        </GridTextContainer>
      </GridItem>
    </InnerContainer>
  );
};

const Grid = ({ customQuestions, header, columns, handleOverflow = () => null }) => {
  const { items } = customQuestions;

  items.sort((a, b) => (a.position > b.position ? 1 : -1));

  const { ref } = useOverflowDetector({
    onChange: useCallback(
      (overflow) => {
        handleOverflow(overflow);
      },
      [handleOverflow]
    )
  });

  const [overflowArray, setOverflowArray] = useState(items || []);

  const updateOverflowArray = (id, overflow) => {
    setOverflowArray((array) => {
      return array.map((item) => {
        if (item.id === id) {
          return { ...item, overflow };
        } else {
          return item;
        }
      });
    });
  };

  useEffect(() => {
    if (overflowArray?.length) {
      if (overflowArray.some((item) => item?.overflow)) {
        handleOverflow(true);
      } else {
        handleOverflow(false);
      }
    }
  }, [handleOverflow, overflowArray]);

  return (
    <OuterContainer ref={ref}>
      {items.map((question, index, array) => {
        return (
          <React.Fragment key={index}>
            {question?.type === "title" && (
              <InnerContainer
                key={question?.position}
                position={question?.position}
                columns={columns || 4}
                title
              >
                {header === "h1" ? (
                  <Heading.H1 tac onClick={() => postMessage(question)}>
                    {question?.answers?.items?.[0]?.text || question?.placeholder}
                  </Heading.H1>
                ) : (
                  <Heading.H2 tac onClick={() => postMessage(question)}>
                    {question?.answers?.items?.[0]?.text || question?.placeholder}
                  </Heading.H2>
                )}
              </InnerContainer>
            )}
            {question?.type === "image" && (
              <InnerContainer
                key={question?.position}
                position={question?.position}
                columns={columns || 4}
              >
                <GridItem>
                  <Photo
                    imgObject={question?.answers?.items?.[0]?.image || null}
                    loading={question?.answers?.items?.[0]?.image?.loading || null}
                    ratio={question?.ratio}
                    onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
                      postMessage({
                        ...question,
                        recommendedWidthPercentage,
                        recommendedHeightPercentage
                      })
                    }
                  />
                </GridItem>
              </InnerContainer>
            )}
            {(question?.type === "subtitle" ||
              (question?.type === "text" && question?.backgroundColor)) && (
              <TextComponent
                question={question}
                array={array}
                index={index}
                columns={columns || 4}
                handleOverflow={(overflow) => updateOverflowArray(question?.id, overflow)}
              />
            )}
          </React.Fragment>
        );
      })}
    </OuterContainer>
  );
};

export default Grid;
