import "@fontsource/quicksand/300.css";
import "@fontsource/eb-garamond/400.css";
import React from "react";
import ReactDOM from "react-dom";
import Page from "./containers/Page";
import Book from "./containers/Book";
import Cover from "./containers/Cover";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Auth } from "@aws-amplify/auth";
import { Storage } from "@aws-amplify/storage";
import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import styledNormalize from "styled-normalize";

const GlobalStyle = createGlobalStyle`
  ${reset}
  ${styledNormalize}
  @font-face {
    font-family: 'La Ronda';
    src: local('La Ronda'), 
        local('LaRonda'), 
        url('fonts/LaRonda-Regular.woff2') format('woff2'),
        url('fonts/LaRonda-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  * { 
    text-rendering: optimizeLegibility; 
    -webkit-font-smoothing: subpixel-antialiased;
    text-transform: none;
    font-kerning: none;
    text-size-adjust: none;
  }
`;

if (
  process.env.REACT_APP_AWS_IDENTITY_POOL_ID &&
  process.env.REACT_APP_AWS_REGION &&
  process.env.REACT_APP_AWS_USER_POOL_ID &&
  process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID &&
  process.env.REACT_APP_AWS_USER_FILES_BUCKET
) {
  Storage.configure({
    bucket: process.env.REACT_APP_AWS_USER_FILES_BUCKET,
    region: process.env.REACT_APP_AWS_REGION
  });
  Auth.configure({
    identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID
  });
}

function AppRouter() {
  return (
    <Router>
      <GlobalStyle />
      <Switch>
        <Route exact path="/page" component={Page} />
        <Route exact path="/book" component={Book} />
        <Route exact path="/cover" component={Cover} />
      </Switch>
    </Router>
  );
}

ReactDOM.render(<AppRouter />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
