import React, { useCallback } from "react";
import styled from "styled-components";
import Photo from "../components/Photo";
import { postMessage } from "../utils/postmessage";
import { useOverflowDetector } from "react-detectable-overflow";

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  height: 90vw;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: -2.5vw;
`;

const PhotosContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2.5vw;
`;

const PhotoContainer = styled.div`
  ${(props) =>
    props.items &&
    `
    width: calc((90vw - (2.5vw * ${props.items.length - 1})) / ${props.items.length});
  `}
`;

const Grid6 = ({ customQuestions, margin, header, handleOverflow = () => null }) => {
  const { items } = customQuestions;

  const { ref } = useOverflowDetector({
    onChange: useCallback(
      (overflow) => {
        handleOverflow(overflow);
      },
      [handleOverflow]
    )
  });

  items.sort((a, b) => (a.position > b.position ? 1 : -1));

  // chunk array into groups of 3
  const chunkedItems = items.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 3);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  return (
    <>
      <OuterContainer margin={margin} ref={ref}>
        {chunkedItems.map((chunk, index) => (
          <PhotosContainer key={index}>
            {chunk
              .filter((question) => question?.type === "image")
              .map((question, index, array) => (
                <PhotoContainer items={array} key={index}>
                  <Photo
                    imgObject={question?.answers?.items?.[0]?.image || null}
                    loading={question?.answers?.items?.[0]?.image?.loading || null}
                    ratio={question?.ratio}
                    onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
                      postMessage({
                        ...question,
                        recommendedWidthPercentage,
                        recommendedHeightPercentage
                      })
                    }
                  />
                </PhotoContainer>
              ))}
          </PhotosContainer>
        ))}
      </OuterContainer>
    </>
  );
};

export default Grid6;
