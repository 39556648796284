import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Page from "./Page";
import BackPage from "../components/BackPage";
import params from "../utils/params";

const Container = styled.div`
  ${(props) =>
    props.view === "print" &&
    `
    @media print {
      page-break-after: always;
    }
    @page {
      size: ${(305 / 25.4) * 96}px ${(305 / 25.4) * 96}px;
    }
    * {
      -webkit-print-color-adjust: exact;
    }
  `}
`;

const Book = ({ location }) => {
  const { view, user: userId, book: bookId, pageStart, pageEnd, export: _export } = params;
  const [customPages, setCustomPages] = useState([]);

  useEffect(() => {
    const fetchBook = async () => {
      try {
        const getBook = await fetch(
          `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/user/${userId}/book/${bookId}`,
          {}
        );
        const json = await getBook.json();
        const pages = json?.customPages?.items;
        setCustomPages(pages);
        console.log(pages);
      } catch (e) {
        // alert(e);
        console.log(e.message);
      }
    };

    if (view === "print") {
      fetchBook();
    }
  }, [bookId, pageEnd, pageStart, userId, view]);

  return (
    <Container view={view}>
      {customPages
        ?.filter((item) => !item.hide && (_export || item.number !== 0))
        .sort((a, b) => (a.number > b.number ? 1 : -1))
        .filter((item, index, array) =>
          pageStart && pageEnd ? index + 1 >= pageStart && index + 1 <= pageEnd : true
        )
        .map((customPage) => (
          <Page key={customPage.id} pageId={customPage.id} location={location} />
        ))}
      {_export && <BackPage width="100vw" height="100vw" />}
    </Container>
  );
};

export default Book;
