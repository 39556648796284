import React, { useCallback } from "react";
import { useOverflowDetector } from "react-detectable-overflow";
import { postMessage } from "../utils/postmessage";
import styled from "styled-components";
import Heading from "../components/Heading";

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const InnerContainer = styled.div`
  width: 90vw;
  order: ${(props) => props.position || 0};
`;

const TitlePage = ({ customQuestions, handleOverflow = () => null }) => {
  const { ref } = useOverflowDetector({
    onChange: useCallback(
      (overflow) => {
        handleOverflow(overflow);
      },
      [handleOverflow]
    )
  });

  return (
    <OuterContainer ref={ref}>
      {customQuestions.items.map((question) => (
        <InnerContainer key={question?.position} position={question?.position}>
          {question?.position === 0 && (
            <Heading.H3 tac onClick={() => postMessage(question)}>
              {question?.answers?.items?.[0]?.text || question?.placeholder}
            </Heading.H3>
          )}
          {question?.position === 1 && (
            <Heading.H4 tac onClick={() => postMessage(question)}>
              {question?.answers?.items?.[0]?.text || question?.placeholder}
            </Heading.H4>
          )}
        </InnerContainer>
      ))}
    </OuterContainer>
  );
};

export default TitlePage;
