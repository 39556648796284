export function postMessage(question) {
  const w = window["ReactNativeWebView"] || window;

  w.postMessage(JSON.stringify(question));
}

export function postImageQualityWarning(
  recommendedWidthPixels,
  recommendedHeightPixels,
  width,
  height
) {
  const w = window["ReactNativeWebView"] || window;

  const message = {
    type: "imageQualityWarning",
    recommendedWidthPixels,
    recommendedHeightPixels,
    width,
    height
  };

  w.postMessage(JSON.stringify(message));
}

export function postReady() {
  const w = window["ReactNativeWebView"] || window;

  const message = {
    type: "ready"
  };

  w.postMessage(JSON.stringify(message));
}

export function postOverflow(overflow) {
  const w = window["ReactNativeWebView"] || window;

  const message = {
    type: "overflow",
    overflow
  };

  w.postMessage(JSON.stringify(message));
}

export function postOverflowWarning() {
  const w = window["ReactNativeWebView"] || window;

  const message = {
    type: "overflowWarning"
  };

  w.postMessage(JSON.stringify(message));
}

export function postImageLoadingError(imgObject) {
  const w = window["ReactNativeWebView"] || window;

  const message = {
    type: "imageLoadingError",
    imgObject
  };

  w.postMessage(JSON.stringify(message));
}
