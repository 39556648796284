import React, { useCallback } from "react";
import styled from "styled-components";
import Photo from "../components/Photo";
import Story from "../components/Story";
import { postMessage } from "../utils/postmessage";
import { useOverflowDetector } from "react-detectable-overflow";

const OuterContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 90vw;
  justify-content: space-between;
  align-items: center;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledPhoto = styled(Photo)`
  width: calc((100% - 2.5vw) / 2);
  ${(props) =>
    props.mb &&
    `
      margin-bottom: ${props.mb}vw;
      `}
`;

const LeftContainer = styled.div`
  width: calc((90vw - ${(props) => props.gutter}vw) / 6 * 4 + 2.5vw);
`;

const RightContainer = styled.div`
  width: calc(90vw - ((90vw - ${(props) => props.gutter}vw) / 6 * 4 + 2.5vw));
  display: flex;
  height: 90vw;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.justify === "center" && `justify-content: center;`}
  ${(props) => props.justify === "end" && `justify-content: flex-end;`}
  align-items: center;
  height: 100%;
  width: 100%;
`;
const StoryCenter = styled(Story)`
  ${(props) =>
    !props.children &&
    `height: 33%;
    
  `}
  width: calc(100% - 2.5vw);
  margin-left: 2.5vw;
`;

const FourGridStoryRight = ({ customQuestions, handleOverflow = () => null }) => {
  const { ref } = useOverflowDetector({
    onChange: useCallback(
      (overflow) => {
        handleOverflow(overflow);
      },
      [handleOverflow]
    )
  });

  const { items } = customQuestions;
  const gutter = 2.5;
  items.sort((a, b) => (a.position > b.position ? 1 : -1));
  return (
    <OuterContainer>
      <LeftContainer gutter={gutter}>
        <TopContainer gutter={gutter}>
          <StyledPhoto
            position={items?.[0]?.position}
            imgObject={items?.[0]?.answers?.items?.[0]?.image || null}
            loading={items?.[0]?.answers?.items?.[0]?.image?.loading || null}
            ratio={items?.[0]?.ratio}
            onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
              postMessage({
                ...items?.[0],
                recommendedWidthPercentage,
                recommendedHeightPercentage
              })
            }
            mb={gutter}
          />
          <StyledPhoto
            position={items?.[1]?.position}
            imgObject={items?.[1]?.answers?.items?.[0]?.image || null}
            loading={items?.[1]?.answers?.items?.[0]?.image?.loading || null}
            ratio={items?.[1]?.ratio}
            onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
              postMessage({
                ...items?.[1],
                recommendedWidthPercentage,
                recommendedHeightPercentage
              })
            }
            mb={gutter}
          />
        </TopContainer>
        <BottomContainer gutter={gutter}>
          <StyledPhoto
            position={items?.[2]?.position}
            imgObject={items?.[2]?.answers?.items?.[0]?.image || null}
            loading={items?.[2]?.answers?.items?.[0]?.image?.loading || null}
            ratio={items?.[2]?.ratio}
            onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
              postMessage({
                ...items?.[2],
                recommendedWidthPercentage,
                recommendedHeightPercentage
              })
            }
          />
          <StyledPhoto
            position={items?.[3]?.position}
            imgObject={items?.[3]?.answers?.items?.[0]?.image || null}
            loading={items?.[3]?.answers?.items?.[0]?.image?.loading || null}
            ratio={items?.[3]?.ratio}
            onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
              postMessage({
                ...items?.[3],
                recommendedWidthPercentage,
                recommendedHeightPercentage
              })
            }
          />
        </BottomContainer>
      </LeftContainer>
      {["story-center"].includes(items?.[4]?.type) && (
        <RightContainer gutter={gutter} ref={ref}>
          <TextContainer
            justify="center"
            key={items?.[4]?.position}
            position={items?.[4]?.position}
          >
            <StoryCenter tac onClick={() => postMessage(items?.[4])}>
              {items?.[4]?.answers?.items?.[0]?.text}
            </StoryCenter>
          </TextContainer>
        </RightContainer>
      )}
    </OuterContainer>
  );
};

export default FourGridStoryRight;
