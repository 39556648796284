import React from "react";
import styled from "styled-components";
import Photo from "../components/Photo";
import { postMessage } from "../utils/postmessage";

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  justify-content: space-between;
  align-items: center;
`;

const TopContainer = styled.div`
  width: calc(((262.5vw + ${(props) => props.gutter}vw) / 9) * 3);
`;
const BottomContainer = styled.div`
  width: calc(((262.5vw + ${(props) => props.gutter}vw) / 9) * 3);
  flex-direction: row;
  display: flex;
  justify-content: space-between;
`;

const LeftContainer = styled.div`
  width: calc((100% - ${(props) => props.gutter}vw) / 2);
`;
const RightContainer = styled.div`
  width: calc((100% - ${(props) => props.gutter}vw) / 2);
`;
const StyledPhoto = styled(Photo)`
  width: 100%;
  ${(props) =>
    props.mb &&
    `
      margin-bottom: ${props.mb}vw;
      `}
`;

const ThreeGrid2 = ({ customQuestions }) => {
  const { items } = customQuestions;
  const gutter = 2.5;
  items.sort((a, b) => (a.position > b.position ? 1 : -1));
  return (
    <OuterContainer>
      <TopContainer gutter={gutter}>
        <StyledPhoto
          position={items?.[0]?.position}
          imgObject={items?.[0]?.answers?.items?.[0]?.image || null}
          loading={items?.[0]?.answers?.items?.[0]?.image?.loading || null}
          ratio={items?.[0]?.ratio}
          onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
            postMessage({
              ...items?.[0],
              recommendedWidthPercentage,
              recommendedHeightPercentage
            })
          }
          mb={gutter}
        />
      </TopContainer>
      <BottomContainer gutter={gutter}>
        <LeftContainer gutter={gutter}>
          <StyledPhoto
            position={items[1].position}
            imgObject={items[1]?.answers?.items?.[0]?.image || null}
            loading={items[1]?.answers?.items?.[0]?.image?.loading || null}
            ratio={items[1]?.ratio}
            onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
              postMessage({
                ...items[1],
                recommendedWidthPercentage,
                recommendedHeightPercentage
              })
            }
          />
        </LeftContainer>
        <RightContainer gutter={gutter}>
          <StyledPhoto
            position={items[2].position}
            imgObject={items[2]?.answers?.items?.[0]?.image || null}
            loading={items[2]?.answers?.items?.[0]?.image?.loading || null}
            ratio={items[2]?.ratio}
            onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
              postMessage({
                ...items[2],
                recommendedWidthPercentage,
                recommendedHeightPercentage
              })
            }
          />
        </RightContainer>
      </BottomContainer>
    </OuterContainer>
  );
};
export default ThreeGrid2;
