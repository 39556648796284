import React, { useState } from "react";
import styled from "styled-components";
import { postOverflowWarning } from "../utils/postmessage";
import FrontPage from "./front-page";
import TitlePage from "./title-page";
import TimelineLeft from "./timeline-left";
import TimelineRight from "./timeline-right";
import TimelineAlt from "./timeline-alt";
import PhotoFullPage from "./photo-full-page";
import PhotosVertical from "./photos-vertical";
import Grid4 from "./4-grid";
import Grid42 from "./4-grid-2";
import Grid4StoryBottom from "./4-grid-story-bottom";
import Grid4StoryRight from "./4-grid-story-right";
import Grid4VerticalOffset from "./4-grid-vertical-offset";
import Grid6 from "./6-grid";
import Story from "./story";
import Address from "./address";
import OffsetPhotosSubtitles from "./offset-photos-subtitles";
import FamilyTree from "./family-tree";
import FamilyTreeSingle from "./family-tree-single";
import PhotoSubtitles from "./photo-subtitles";
import PhotosHorizontal from "./photos-horizontal";
import Grid4Assymetric from "./4-grid-assymetric";
import Grid from "./grid";
import Grid3 from "./3-grid";
import Grid32 from "./3-grid-2";
import Grid33 from "./3-grid-3";
import Chart from "./chart";
import Grid4Offset from "./4-grid-offset";
import Quote from "./quote";
import params from "../utils/params";

const SquareContainer = styled.div`
  width: 100vw;
  height: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  ${(props) =>
    props.backgroundColor &&
    `
    background-color: ${props.backgroundColor};
    color: white;
    }
  `}
`;

const Meta = styled.div`
  ${(props) => props.metaHidden && `opacity:0;`}
  position: absolute;
  left: 0;
  z-index: 1000;
  font-size: 5vw;
`;

const WarningContainer = styled.div`
  z-index: ${(props) => props.zIndex};
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  width: 9vw;
  height: 9vw;
  justify-content: center;
  align-items: center;
`;

const Page = ({ page, user }) => {
  const customQuestions = page?.customQuestions || page?.questions;
  const { template, number } = page;
  const [metaHidden, setMetaHidden] = useState(false);
  const [overflow, setOverflow] = useState(false);
  const { view } = params;

  const handleOverflow = (overflow) => {
    setOverflow(overflow ? page.id : false);
  };

  return (
    <React.Fragment>
      {process.env.REACT_APP_ENVIRONMENT !== "production" &&
        process.env.REACT_APP_ENVIRONMENT !== "staging" &&
        view !== "print" && (
          <Meta metaHidden={metaHidden} onClick={() => setMetaHidden(!metaHidden)}>
            {number} {template}
          </Meta>
        )}

      <SquareContainer key={page.id} backgroundColor={page.backgroundColor}>
        <>
          {(() => {
            switch (template) {
              case "front-page":
                return (
                  <FrontPage
                    user={user}
                    customQuestions={customQuestions}
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "title-page":
              case "book-title":
                return (
                  <TitlePage
                    user={user}
                    customQuestions={customQuestions}
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "timeline-left":
                return (
                  <TimelineLeft
                    user={user}
                    customQuestions={customQuestions}
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "timeline-right":
                return (
                  <TimelineRight
                    user={user}
                    customQuestions={customQuestions}
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "timeline-alt-1":
              case "timeline-alt-2":
                return (
                  <TimelineAlt
                    user={user}
                    customQuestions={customQuestions}
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                    template={template}
                  />
                );
              case "photo-full-page":
                return (
                  <PhotoFullPage
                    user={user}
                    customQuestions={customQuestions}
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "photo-full-page-h1":
                return (
                  <PhotoFullPage
                    user={user}
                    customQuestions={customQuestions}
                    header="h1"
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "photo-full-page-margin":
                return (
                  <PhotoFullPage
                    margin
                    user={user}
                    customQuestions={customQuestions}
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "4-grid":
                return (
                  <Grid4
                    user={user}
                    customQuestions={customQuestions}
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "4-grid-vertical-offset":
                return (
                  <Grid4VerticalOffset
                    user={user}
                    customQuestions={customQuestions}
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "6-grid":
                return (
                  <Grid6
                    user={user}
                    customQuestions={customQuestions}
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "4-grid-story-bottom":
                return (
                  <Grid4StoryBottom
                    user={user}
                    customQuestions={customQuestions}
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "4-grid-story-right":
                return (
                  <Grid4StoryRight
                    user={user}
                    customQuestions={customQuestions}
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "4-grid-2":
                return (
                  <Grid42
                    user={user}
                    customQuestions={customQuestions}
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "grid":
                return (
                  <Grid
                    user={user}
                    customQuestions={customQuestions}
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "9-grid":
                return (
                  <Grid
                    user={user}
                    customQuestions={customQuestions}
                    columns={3}
                    header="h1"
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "photos-vertical":
                return (
                  <PhotosVertical
                    user={user}
                    customQuestions={customQuestions}
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "photos-vertical-2":
                return (
                  <PhotosVertical
                    user={user}
                    customQuestions={customQuestions}
                    version="2landscape"
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "story":
                return (
                  <Story
                    user={user}
                    customQuestions={customQuestions}
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "story-center":
                return (
                  <Story
                    user={user}
                    customQuestions={customQuestions}
                    center
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "story-h2":
                return (
                  <Story
                    user={user}
                    customQuestions={customQuestions}
                    header="h2"
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "story-twin":
                return (
                  <Story
                    user={user}
                    customQuestions={customQuestions}
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "address":
                return (
                  <Address
                    user={user}
                    customQuestions={customQuestions}
                    header="h2"
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "offset-photos-subtitles":
                return (
                  <OffsetPhotosSubtitles
                    user={user}
                    customQuestions={customQuestions}
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "photo-subtitles":
                return (
                  <PhotoSubtitles
                    user={user}
                    customQuestions={customQuestions}
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "4-grid-offset":
                return (
                  <Grid4Offset
                    user={user}
                    customQuestions={customQuestions}
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "4-grid-large-photos":
                return (
                  <Grid4Offset
                    user={user}
                    customQuestions={customQuestions}
                    largePhotos
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "3-grid":
                return (
                  <Grid3
                    user={user}
                    customQuestions={customQuestions}
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "3-grid-2":
                return (
                  <Grid32
                    user={user}
                    customQuestions={customQuestions}
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "3-grid-3":
                return (
                  <Grid33
                    user={user}
                    customQuestions={customQuestions}
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "4-grid-assymetric-top-left":
                return (
                  <Grid4Assymetric
                    user={user}
                    customQuestions={customQuestions}
                    layout="top-left"
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "4-grid-assymetric-bottom-right":
                return (
                  <Grid4Assymetric
                    user={user}
                    customQuestions={customQuestions}
                    layout="bottom-right"
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "family-tree":
                return (
                  <FamilyTree
                    user={user}
                    customQuestions={customQuestions}
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "family-tree-single":
                return (
                  <FamilyTreeSingle
                    user={user}
                    customQuestions={customQuestions}
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "chart":
                return (
                  <Chart
                    user={user}
                    customQuestions={customQuestions}
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "photos-horizontal":
              case "2-grid":
                return (
                  <PhotosHorizontal
                    user={user}
                    customQuestions={customQuestions}
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "quote":
                return (
                  <Quote
                    user={user}
                    customQuestions={customQuestions}
                    handleOverflow={(overflow) => handleOverflow(overflow)}
                  />
                );
              case "error":
                return;
              default:
                return null;
            }
          })()}
          {view !== "print" &&
            view !== "review" &&
            view !== "printPreview" &&
            overflow === page.id && (
              <WarningContainer onClick={() => postOverflowWarning()} zIndex={11000}>
                <svg
                  width="9vw"
                  height="9vw"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.1029 7.75C13.8349 4.75 18.1651 4.75 19.8971 7.75L25.0933 16.75C26.8253 19.75 24.6603 23.5 21.1962 23.5H10.8038C7.33974 23.5 5.17468 19.75 6.90673 16.75L12.1029 7.75Z"
                    fill="#D6A969"
                    stroke="white"
                  />
                  <path
                    d="M16.9783 10.0928C16.9783 10.2744 16.9725 10.4385 16.9608 10.585C16.949 10.7314 16.9256 10.9629 16.8904 11.2793L16.2049 17.0361H15.4139L14.7283 11.2793C14.6697 10.7637 14.6404 10.3682 14.6404 10.0928C14.6404 9.64746 14.7576 9.31934 14.992 9.1084C15.2264 8.89746 15.493 8.79199 15.7918 8.79199C16.1317 8.79199 16.4129 8.90039 16.6356 9.11719C16.8641 9.32812 16.9783 9.65332 16.9783 10.0928ZM17.1893 19.9189C17.1893 20.3057 17.0545 20.6309 16.785 20.8945C16.5154 21.1582 16.1903 21.29 15.8094 21.29C15.4344 21.29 15.1121 21.1582 14.8426 20.8945C14.5731 20.6309 14.4383 20.3057 14.4383 19.9189C14.4383 19.5322 14.5701 19.2041 14.8338 18.9346C15.0975 18.665 15.4227 18.5303 15.8094 18.5303C16.1961 18.5303 16.5213 18.665 16.785 18.9346C17.0545 19.1982 17.1893 19.5264 17.1893 19.9189Z"
                    fill="white"
                  />
                </svg>
              </WarningContainer>
            )}
        </>
      </SquareContainer>
    </React.Fragment>
  );
};

export default Page;
