import React from "react";
import styled from "styled-components";
import Photo from "../components/Photo";
import { postMessage } from "../utils/postmessage";
const OuterContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 90vw;
  height: 90vw;
  justify-content: center;
  align-items: center;
  margin-right: -2.5vw;
`;
const PhotoContainer = styled.div`
  order: ${(props) => props.position || 0};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-right: 2.5vw;
`;
const StyledPhoto = styled(Photo)`
  width: calc((87.5vw / 5) * 2);
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  ${(props) => props.center && `align-items: center;`}
  ${(props) => props.end && `align-items: flex-end;`}
  ${(props) => props.start && `align-items: flex-start;`}
  ${(props) => props.top && `justify-content: flex-start;`}
  ${(props) => props.bottom && `justify-content: flex-end;`}
`;

const PhotosVertical = ({ customQuestions, version, handleOverflow = () => null }) => {
  const { items } = customQuestions;

  items.sort((a, b) => (a.position > b.position ? 1 : -1));

  // chunk array into groups of 2
  const chunkedItems = items.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 2);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  return (
    <OuterContainer>
      {chunkedItems?.map((chunk, index) => (
        <Column key={index} center>
          {chunk?.map((question, index, array) => (
            <PhotoContainer key={question?.position} position={question?.position}>
              {question?.type === "image" && (
                <StyledPhoto
                  position={question?.position}
                  imgObject={question?.answers?.items?.[0]?.image || null}
                  loading={question?.answers?.items?.[0]?.image?.loading || null}
                  ratio={question?.ratio}
                  onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
                    postMessage({
                      ...question,
                      recommendedWidthPercentage,
                      recommendedHeightPercentage
                    })
                  }
                  version={version}
                  mb={array?.[index + 1]?.type === "image" ? 2.5 : 0}
                />
              )}
            </PhotoContainer>
          ))}
        </Column>
      ))}
    </OuterContainer>
  );
};

export default PhotosVertical;
