import React, { useCallback } from "react";
import styled from "styled-components";
import Story from "../components/Story";
import { postMessage } from "../utils/postmessage";
import { useOverflowDetector } from "react-detectable-overflow";

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 90vw;
  justify-content: center;
  align-items: center;
`;
const InnerContainer = styled.div`
  width: 67vw;
  order: ${(props) => props.position || 0};
`;
const StyledStory = styled(Story)`
  font-size: 6.5vw;
  line-height: 7.5vw;
`;

const Quote = ({ customQuestions, handleOverflow = () => null }) => {
  const { ref } = useOverflowDetector({
    onChange: useCallback(
      (overflow) => {
        handleOverflow(overflow);
      },
      [handleOverflow]
    )
  });
  return (
    <OuterContainer ref={ref}>
      {customQuestions?.items.map((question) => (
        <InnerContainer key={question?.position} position={question?.position}>
          {question?.type === "story" && (
            <StyledStory tac onClick={() => postMessage(question)}>
              {question?.answers?.items?.[0]?.text || question?.placeholder}
            </StyledStory>
          )}
        </InnerContainer>
      ))}
    </OuterContainer>
  );
};

export default Quote;
