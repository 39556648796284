import React, { useCallback } from "react";
import styled from "styled-components";
import Photo from "../components/Photo";
import Story from "../components/Story";
import Heading from "../components/Heading";
import { postMessage } from "../utils/postmessage";
import { useOverflowDetector } from "react-detectable-overflow";

const OuterContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 43.75vw);
  grid-template-rows: repeat(2, 43.75vw);
  grid-column-gap: 2.5vw;
  grid-row-gap: 2.5vw;
  width: 90vw;
  height: 90vw;
  align-items: center;
  position: relative;
`;
const InnerContainer = styled.div`
  ${(props) => [0, 1].includes(props.position) && "align-self: end;"}
  ${(props) => [2, 3].includes(props.position) && "align-self: start;"}
  ${(props) => [0, 2].includes(props.position) && "justify-self: end;"}
  ${(props) => [1, 3].includes(props.position) && "justify-self: start;"}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  order: ${(props) => props.position || 0};
  width: 43.75vw;
`;

const StoryCenterContainer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: calc((90vw - (90vw - 2.5vw) / 6 * 4 - 7.5vw) / 2);
  width: 90vw;
`;

const StoryCenter = styled(Story)`
  ${(props) => !props.children && `height: calc((90vw - (90vw - 2.5vw) / 6 * 4 - 7.5vw) / 2);`}
  width:50vw;
`;

const FourGridStoryBottom = ({ customQuestions, handleOverflow = () => null }) => {
  const { ref } = useOverflowDetector({
    onChange: useCallback(
      (overflow) => {
        handleOverflow(overflow);
      },
      [handleOverflow]
    )
  });

  const { items } = customQuestions;
  items.sort((a, b) => (a.position > b.position ? 1 : -1));
  return (
    <OuterContainer>
      {items.map((question, index, array) => (
        <React.Fragment key={index}>
          {question?.type === "image" && (
            <InnerContainer key={question?.position} position={question?.position}>
              <Photo
                imgObject={question?.answers?.items?.[0]?.image || null}
                loading={question?.answers?.items?.[0]?.image?.loading || null}
                ratio={question?.ratio}
                onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
                  postMessage({
                    ...question,
                    recommendedWidthPercentage,
                    recommendedHeightPercentage
                  })
                }
              />
            </InnerContainer>
          )}
          {question?.type === "subtitle" && (
            <InnerContainer key={question?.position} position={question?.position}>
              <Heading.H4 mb={2.5} mt={2.5} tac onClick={() => postMessage(question)}>
                {question?.answers?.items?.[0]?.text || question?.placeholder}
              </Heading.H4>
              <Story onClick={() => postMessage(array?.[index + 1])}>
                {array?.[index + 1]?.answers?.items?.[0]?.text}
              </Story>
            </InnerContainer>
          )}
          {question?.type === "story-center" && (
            <StoryCenterContainer ref={ref}>
              <StoryCenter tac onClick={() => postMessage(question)}>
                {question?.answers?.items?.[0]?.text || question?.placeholder}
              </StoryCenter>
            </StoryCenterContainer>
          )}
        </React.Fragment>
      ))}
    </OuterContainer>
  );
};

export default FourGridStoryBottom;
