import React from "react";
import styled from "styled-components";
import Heading from "../components/Heading";
import Photo from "../components/Photo";
import { postMessage } from "../utils/postmessage";
import Text from "../components/Text";

const OuterContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vw;
  position: relative;
  justify-content: center;
  align-items: center;
`;
const InnerContainer = styled.div`
  order: ${(props) => props.position || 0};
`;

const StyledHeading = styled(Heading.H1)`
  position: absolute;
  left: 5vw;
  top: 5vw;
  padding-left: 2vw;
  width: 30vw;
`;

const StyledPhoto1 = styled(Photo)`
  width: calc(
    92.5vw /
      (
        (${(props) => props.ratio.split(":")[0]} / ${(props) => props.ratio.split(":")[1]}) *
          ${(props) => props.ratio.split(":")[0]} + ${(props) => props.ratio.split(":")[1]}
      ) *
      (
        (${(props) => props.ratio.split(":")[0]} / ${(props) => props.ratio.split(":")[1]}) *
          ${(props) => props.ratio.split(":")[0]}
      )
  );
  margin-left: 5vw;
`;

const StyledPhoto2 = styled(Photo)`
  width: calc(
    90vw - 92.5vw /
      (
        (${(props) => props.ratio.split(":")[0]} / ${(props) => props.ratio.split(":")[1]}) *
          ${(props) => props.ratio.split(":")[0]} + ${(props) => props.ratio.split(":")[1]}
      ) *
      (
        (${(props) => props.ratio.split(":")[0]} / ${(props) => props.ratio.split(":")[1]}) *
          ${(props) => props.ratio.split(":")[0]}
      ) * 2
  );
  margin-left: 2.5vw;
`;

const StyledPhoto3 = styled(Photo)`
  width: calc(
    92.5vw /
      (
        (${(props) => props.ratio.split(":")[0]} / ${(props) => props.ratio.split(":")[1]}) *
          ${(props) => props.ratio.split(":")[0]} + ${(props) => props.ratio.split(":")[1]}
      ) *
      (
        (${(props) => props.ratio.split(":")[0]} / ${(props) => props.ratio.split(":")[1]}) *
          ${(props) => props.ratio.split(":")[0]}
      )
  );
  margin-left: 2.5vw;
`;

const VerticalLine = styled.div`
  height: 25vw;
  width: 1px;
  box-sizing: border-box;
  border-left: 1px solid black;
  position: absolute;
  z-index: -1;
`;

const VerticalLine1 = styled(VerticalLine)`
  left: 10vw;
  bottom: 18vw;
`;

const VerticalLine2 = styled(VerticalLine)`
  right: 47.875vw;
  top: 15vw;
`;

const VerticalLine3 = styled(VerticalLine)`
  right: 10vw;
  top: 20vw;
`;

const StyledSubtitle1 = styled(Text)`
  position: absolute;
  left: 10vw;
  bottom: 15vw;
  z-index: 10000;
`;

const StyledSubtitle2 = styled(Text)`
  position: absolute;
  right: 47.875vw;
  top: 12vw;
  z-index: 10000;
`;

const StyledSubtitle3 = styled(Text)`
  position: absolute;
  right: 10vw;
  top: 17vw;
  z-index: 10000;
`;

const TimeLineRight = ({ customQuestions }) => {
  return (
    <OuterContainer>
      {customQuestions.items.map((question) => (
        <InnerContainer key={question?.position} position={question?.position}>
          {question?.position === 0 && (
            <StyledHeading tal mb5 onClick={() => postMessage(question)}>
              {question?.answers?.items?.[0]?.text || question?.placeholder}
            </StyledHeading>
          )}
          {question?.position === 1 && (
            <>
              <StyledPhoto1
                imgObject={question?.answers?.items?.[0]?.image || null}
                loading={question?.answers?.items?.[0]?.image?.loading || null}
                ratio={question?.ratio}
                onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
                  postMessage({
                    ...question,
                    recommendedWidthPercentage,
                    recommendedHeightPercentage
                  })
                }
              />
              <VerticalLine1 />
            </>
          )}
          {question?.position === 2 && (
            <>
              <StyledPhoto2
                imgObject={question?.answers?.items?.[0]?.image || null}
                loading={question?.answers?.items?.[0]?.image?.loading || null}
                ratio={question?.ratio}
                onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
                  postMessage({
                    ...question,
                    recommendedWidthPercentage,
                    recommendedHeightPercentage
                  })
                }
              />
              <VerticalLine2 />
            </>
          )}
          {question?.position === 3 && (
            <>
              <StyledPhoto3
                imgObject={question?.answers?.items?.[0]?.image || null}
                loading={question?.answers?.items?.[0]?.image?.loading || null}
                ratio={question?.ratio}
                onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
                  postMessage({
                    ...question,
                    recommendedWidthPercentage,
                    recommendedHeightPercentage
                  })
                }
              />
              <VerticalLine3 />
            </>
          )}
          {question?.position === 4 && (
            <StyledSubtitle1 subtitle tac onClick={() => postMessage(question)}>
              {question?.answers?.items?.[0]?.text || question?.placeholder}
            </StyledSubtitle1>
          )}
          {question?.position === 5 && (
            <StyledSubtitle2 subtitle tac onClick={() => postMessage(question)}>
              {question?.answers?.items?.[0]?.text || question?.placeholder}
            </StyledSubtitle2>
          )}
          {question?.position === 6 && (
            <StyledSubtitle3 subtitle tac onClick={() => postMessage(question)}>
              {question?.answers?.items?.[0]?.text || question?.placeholder}
            </StyledSubtitle3>
          )}
        </InnerContainer>
      ))}
    </OuterContainer>
  );
};

export default TimeLineRight;
