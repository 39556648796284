import React from "react";
import styled from "styled-components";

const AspectRatio = styled.div`
  height: 0;
  overflow: hidden;
  padding-top: calc(
    (${(props) => props.ratio.split(":")[1]} / ${(props) => props.ratio.split(":")[0]}) * 100%
  );

  position: relative;
`;
const Inside = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Ratio = ({ ratio, children, className }) => (
  <AspectRatio className={className} ratio={ratio}>
    <Inside>{children}</Inside>
  </AspectRatio>
);
export default Ratio;
