import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Photo from "../components/Photo";
import Heading from "../components/Heading";
import Story from "../components/Story";
import { postMessage } from "../utils/postmessage";
import { useOverflowDetector } from "react-detectable-overflow";

const OuterContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 90vw;
  height: 100vw;
  justify-content: center;
  align-items: center;
`;
const PhotoContainer = styled.div`
  order: ${(props) => props.position || 0};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
const TextInnerContainer = styled.div`
  order: ${(props) => props.position || 0};
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.justify === "end" ? "flex-end" : "center")};
  align-items: center;
  max-height: 90vw;
  width: 100%;
`;
const StyledPhoto = styled(Photo)`
  ${(props) =>
    props.version === "2landscape"
      ? `width: calc((87.5vw / 4) * 3);`
      : `width: calc((97.5vw / 5) * 3);`}
`;

const StyledHeading = styled(Heading.H4)``;

const StoryCenter = styled(Story)`
  ${(props) => !props.children && `height: 33%;`}
  margin-left: 2.5vw;
  width: calc(100% - 2.5vw);
`;

const TextOuterContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 91vw;
  margin-left: 2.5vw;
  width: calc(100% - 2.5vw);
`;

const TextComponent = ({ question, array, index, handleOverflow }) => {
  const { ref } = useOverflowDetector({
    onChange: useCallback(
      (overflow) => {
        handleOverflow(overflow);
      },
      [handleOverflow]
    )
  });

  return (
    <TextOuterContainer ref={ref}>
      {["title", "subtitle"].includes(question?.type) && (
        <TextInnerContainer justify="end" key={question?.position} position={question?.position}>
          {question?.type === "subtitle" && (
            <StyledHeading mb={2.5} tal onClick={() => postMessage(question)}>
              {question?.answers?.items?.[0]?.text || question?.placeholder}
            </StyledHeading>
          )}
          {question?.type === "title" && (
            <StyledHeading mb={2.5} tal onClick={() => postMessage(question)}>
              {question?.answers?.items?.[0]?.text || question?.placeholder}
            </StyledHeading>
          )}
          <Story onClick={() => postMessage(array[index + 1])}>
            {array[index + 1]?.answers?.items?.[0]?.text}
          </Story>
        </TextInnerContainer>
      )}

      {["story-center"].includes(question?.type) && (
        <TextInnerContainer justify="center" key={question?.position} position={question?.position}>
          <StoryCenter tac onClick={() => postMessage(question)}>
            {question?.answers?.items?.[0]?.text}
          </StoryCenter>
        </TextInnerContainer>
      )}
    </TextOuterContainer>
  );
};

const PhotosVertical = ({ customQuestions, version, handleOverflow = () => null }) => {
  const { items } = customQuestions;

  items.sort((a, b) => (a.position > b.position ? 1 : -1));

  const [overflowArray, setOverflowArray] = useState(items || []);

  const updateOverflowArray = (id, overflow) => {
    setOverflowArray((array) => {
      return array.map((item) => {
        if (item.id === id) {
          return { ...item, overflow };
        } else {
          return item;
        }
      });
    });
  };

  useEffect(() => {
    if (overflowArray?.length) {
      if (overflowArray.some((item) => item?.overflow)) {
        handleOverflow(true);
      } else {
        handleOverflow(false);
      }
    }
  }, [handleOverflow, overflowArray]);

  return (
    <OuterContainer>
      {items?.map((question, index, array) => (
        <React.Fragment key={index}>
          {!question?.position && (
            <PhotoContainer key={question?.position} position={question?.position}>
              {question?.type === "image" && (
                <StyledPhoto
                  position={question?.position}
                  imgObject={question?.answers?.items?.[0]?.image || null}
                  loading={question?.answers?.items?.[0]?.image?.loading || null}
                  ratio={question?.ratio}
                  onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
                    postMessage({
                      ...question,
                      recommendedWidthPercentage,
                      recommendedHeightPercentage
                    })
                  }
                  version={version}
                  mb={array?.[index + 1]?.type === "image" ? 2.5 : 0}
                />
              )}
              {array?.[index + 1]?.type === "image" && (
                <StyledPhoto
                  position={array?.[index + 1]?.position}
                  imgObject={array?.[index + 1]?.answers?.items?.[0]?.image || null}
                  loading={array?.[index + 1]?.answers?.items?.[0]?.image?.loading || null}
                  ratio={array?.[index + 1]?.ratio}
                  onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
                    postMessage({
                      ...array?.[index + 1],
                      recommendedWidthPercentage,
                      recommendedHeightPercentage
                    })
                  }
                  version={version}
                />
              )}
            </PhotoContainer>
          )}
          {["title", "subtitle", "story-center"].includes(question?.type) && (
            <TextComponent
              question={question}
              array={array}
              index={index}
              handleOverflow={(overflow) => updateOverflowArray(question?.id, overflow)}
            />
          )}
        </React.Fragment>
      ))}
    </OuterContainer>
  );
};

export default PhotosVertical;
