import React, { useCallback } from "react";
import { useOverflowDetector } from "react-detectable-overflow";
import styled from "styled-components";
import Photo from "../components/Photo";
import Text from "../components/Text";
import Heading from "../components/Heading";
import { postMessage } from "../utils/postmessage";

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  height: 90vw;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const StyledPhoto = styled(Photo)`
  width: 90vw;
`;

const OuterSubtitlesContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  width: 50%;
  justify-content: space-between;
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: center;
`;
const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-end;
  justify-content: center;
`;

const InnerSubtitlesContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: center;
`;

const Subtitles = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
`;

const LeftColumnInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
`;

const RightColumnInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
`;

const PhotoSubtitles = ({ customQuestions, handleOverflow = () => null }) => {
  const { ref } = useOverflowDetector({
    onChange: useCallback(
      (overflow) => {
        handleOverflow(overflow);
      },
      [handleOverflow]
    )
  });
  const { items } = customQuestions;
  items.sort((a, b) => (a.position > b.position ? 1 : -1));
  return (
    <OuterContainer ref={ref}>
      <StyledPhoto
        imgObject={items?.[0]?.answers?.items?.[0]?.image || null}
        loading={items?.[0]?.answers?.items?.[0]?.image?.loading || null}
        ratio={items?.[0]?.ratio}
        onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
          postMessage({
            ...items?.[0],
            recommendedWidthPercentage,
            recommendedHeightPercentage
          })
        }
      />
      <OuterSubtitlesContainer>
        <LeftColumn>
          <LeftColumnInner>
            <InnerSubtitlesContainer>
              <Subtitles>
                <Heading.H4 mb={1} onClick={() => postMessage(items[1])}>
                  {items[1]?.answers?.items?.[0]?.text || items[1]?.placeholder}
                </Heading.H4>
                <Text subtitle onClick={() => postMessage(items[2])}>
                  {items[2]?.answers?.items?.[0]?.text || items[2]?.placeholder}
                </Text>
              </Subtitles>
            </InnerSubtitlesContainer>
            <InnerSubtitlesContainer>
              <Subtitles>
                <Heading.H4 mb={1} onClick={() => postMessage(items[5])}>
                  {items[5]?.answers?.items?.[0]?.text || items[5]?.placeholder}
                </Heading.H4>
                <Text subtitle onClick={() => postMessage(items[6])}>
                  {items[6]?.answers?.items?.[0]?.text || items[6]?.placeholder}
                </Text>
              </Subtitles>
            </InnerSubtitlesContainer>
          </LeftColumnInner>
        </LeftColumn>
        <RightColumn>
          <RightColumnInner>
            <InnerSubtitlesContainer>
              <Subtitles>
                <Heading.H4 mb={1} onClick={() => postMessage(items[3])}>
                  {items[3]?.answers?.items?.[0]?.text || items[3]?.placeholder}
                </Heading.H4>
                <Text subtitle onClick={() => postMessage(items[4])}>
                  {items[4]?.answers?.items?.[0]?.text || items[4]?.placeholder}
                </Text>
              </Subtitles>
            </InnerSubtitlesContainer>
            <InnerSubtitlesContainer>
              <Subtitles>
                <Heading.H4 mb={1} onClick={() => postMessage(items[7])}>
                  {items[7]?.answers?.items?.[0]?.text || items[7]?.placeholder}
                </Heading.H4>
                <Text subtitle onClick={() => postMessage(items[8])}>
                  {items[8]?.answers?.items?.[0]?.text || items[8]?.placeholder}
                </Text>
              </Subtitles>
            </InnerSubtitlesContainer>
          </RightColumnInner>
        </RightColumn>
      </OuterSubtitlesContainer>
    </OuterContainer>
  );
};

export default PhotoSubtitles;
