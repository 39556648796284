import React from "react";
import styled from "styled-components";
import Photo from "../components/Photo";
import { postMessage } from "../utils/postmessage";

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  justify-content: space-between;
  align-items: center;
`;

const TopContainer = styled.div`
  width: 90vw;
  display: flex;
  justify-content: space-between;
`;
const TopLeftContainer = styled.div`
  width: calc(((90vw - ${(props) => props.gutter}vw) / 6.5) * 4.5);
  margin-right: ${(props) => props.gutter}vw;
`;
const TopRightContainer = styled.div`
  flex-grow: 1;
  display: flex;
`;
const BottomContainer = styled.div`
  width: 90vw;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
`;

const BottomLeftContainer = styled.div`
  width: calc((100% - ${(props) => props.gutter}vw) / 2);
`;
const BottomRightContainer = styled.div`
  width: calc((100% - ${(props) => props.gutter}vw) / 2);
`;
const StyledPhoto = styled(Photo)`
  width: 100%;
  ${(props) =>
    props.mb &&
    `
      margin-bottom: ${props.mb}vw;
      `}
`;

const FourGrid2 = ({ customQuestions }) => {
  const { items } = customQuestions;
  const gutter = 2.5;
  items.sort((a, b) => (a.position > b.position ? 1 : -1));
  return (
    <OuterContainer>
      <TopContainer gutter={gutter}>
        <TopLeftContainer gutter={gutter}>
          <StyledPhoto
            position={items?.[0]?.position}
            imgObject={items?.[0]?.answers?.items?.[0]?.image || null}
            loading={items?.[0]?.answers?.items?.[0]?.image?.loading || null}
            ratio={items?.[0]?.ratio}
            onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
              postMessage({
                ...items?.[0],
                recommendedWidthPercentage,
                recommendedHeightPercentage
              })
            }
            mb={gutter}
          />
        </TopLeftContainer>
        <TopRightContainer gutter={gutter}>
          <StyledPhoto
            position={items[1]?.position}
            imgObject={items[1]?.answers?.items?.[0]?.image || null}
            loading={items[1]?.answers?.items?.[0]?.image?.loading || null}
            ratio={items[1]?.ratio}
            onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
              postMessage({
                ...items[1],
                recommendedWidthPercentage,
                recommendedHeightPercentage
              })
            }
            mb={gutter}
          />
        </TopRightContainer>
      </TopContainer>
      <BottomContainer gutter={gutter}>
        <BottomLeftContainer gutter={gutter}>
          <StyledPhoto
            position={items[2].position}
            imgObject={items[2]?.answers?.items?.[0]?.image || null}
            loading={items[2]?.answers?.items?.[0]?.image?.loading || null}
            ratio={items[2]?.ratio}
            onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
              postMessage({
                ...items[2],
                recommendedWidthPercentage,
                recommendedHeightPercentage
              })
            }
          />
        </BottomLeftContainer>
        <BottomRightContainer gutter={gutter}>
          <StyledPhoto
            position={items[3].position}
            imgObject={items[3]?.answers?.items?.[0]?.image || null}
            loading={items[3]?.answers?.items?.[0]?.image?.loading || null}
            ratio={items[3]?.ratio}
            onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
              postMessage({
                ...items[3],
                recommendedWidthPercentage,
                recommendedHeightPercentage
              })
            }
          />
        </BottomRightContainer>
      </BottomContainer>
    </OuterContainer>
  );
};

export default FourGrid2;
