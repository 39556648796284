import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { Storage } from "@aws-amplify/storage";
import useDimensions from "react-use-dimensions";
import Ratio from "./Ratio";
import { Camera as CameraIcon } from "styled-icons/fa-solid";
import { Spinner as SpinnerIcon } from "styled-icons/fa-solid";
import { postImageQualityWarning, postImageLoadingError } from "../utils/postmessage";
import { isIOS } from "react-device-detect";
import params from "../utils/params";

const OuterContainer = styled.div`
  width: ${(props) => props.width || "100%"};
  ${(props) =>
    props.mb &&
    `
      margin-bottom: ${props.mb}vw;
    `}
`;

const InnerContainer = styled(Ratio)`
  width: 100%;
  ${(props) =>
    props.view !== "printPreview" &&
    props.view !== "print" &&
    !props.edited &&
    `background-color: #c4c4c4;
  `}
`;

const PlaceholderInnerContainer = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 10000;
`;

const StyledCameraIcon = styled(CameraIcon)`
  width: 10vw;
  color: rgba(0, 0, 0, 0.25);
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Img = styled.img`
  object-fit: cover;
  ${(props) => props.edited && `object-fit:contain;`}
  width: 100%;
  height: 100%;

  display: ${(props) => (props.visible ? "block" : "none")};
`;

const WarningContainer = styled.div`
  z-index: ${(props) => props.zIndex};
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  width: 9vw;
  height: 9vw;
  justify-content: center;
  align-items: center;
`;

const UploadSpinnerContainer = styled.div`
  z-index: 10000;
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 9vw;
  height: 9vw;
  justify-content: center;
  align-items: center;
`;

const UploadSpinnerIcon = styled(SpinnerIcon)`
  width: 5vw;
  color: #405577;
  animation: 2s linear ${spin} infinite;
`;

const Photo = ({ imgObject, ratio, onClick, width, className, mb, loading }) => {
  const viewPortScale = 1 / window.devicePixelRatio;
  const [ref, { width: recommendedWidth, height: recommendedHeight }] = useDimensions();
  const { view, identity } = params;

  const [imageUri, setImageUri] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [retry, setRetry] = useState(0);

  const ratioWidth = ratio.split(":")[0];
  const ratioHeight = ratio.split(":")[1];

  let actualWidth = imgObject?.width;
  let actualHeight = imgObject?.height;

  //calculate the default cropped width and height
  if (!imgObject?.editedKey && ratioWidth > ratioHeight) {
    actualHeight = Math.round(imgObject?.width * (ratioHeight / ratioWidth));
  }

  if (!imgObject?.editedKey && ratioHeight > ratioWidth) {
    actualWidth = Math.round(imgObject?.height * (ratioWidth / ratioHeight));
  }

  if (!imgObject?.editedKey && ratioHeight === ratioWidth) {
    actualWidth = Math.min(imgObject?.width, imgObject?.height);
    actualHeight = Math.min(imgObject?.width, imgObject?.height);
  }

  const recommendedWidthPercentage = (
    Math.round((recommendedWidth / window.outerWidth) * 200) / 200
  ).toFixed(4);
  const recommendedHeightPercentage = (
    Math.round((recommendedHeight / window.outerHeight) * 200) / 200
  ).toFixed(4);
  const recommendedWidthInches = Math.round(
    (30 / 2.54) * recommendedWidthPercentage * viewPortScale
  );
  const recommendedWidthPixels = Math.round(recommendedWidthInches * 150) * (isIOS ? 8 / 3 : 1); // for some reason iOS is 8/3 times the pixels
  const recommendedHeightPixels = recommendedWidthPixels / (ratioWidth / ratioHeight);
  const isImageQualityWarning =
    recommendedWidthPixels > actualWidth && recommendedHeightPixels > actualHeight;

  useEffect(() => {
    const imgKey =
      view === "preview" || view === "printPreview" || view === "review"
        ? imgObject?.thumbnailKey
        : imgObject?.editedKey || imgObject?.originalKey;

    const getImageUri = async () => {
      try {
        setIsLoading(true);
        setIsError(false);
        const keyName = imgKey.split(".")[0];
        const keyEnding = imgKey.split(".")[1];
        const combined = keyName + "." + keyEnding;
        const imgUri = await Storage.get(combined, {
          level: "protected",
          identityId: process.env.REACT_APP_AWS_REGION + ":" + identity
        });
        setImageUri(imgUri);
      } catch (e) {
        // alert(e);
        console.log(e.message);
      }
    };

    if (!imgObject?.base64 && imgKey) {
      getImageUri();
    } else {
      setImageUri(null);
    }
  }, [retry, view, identity, imgObject]);

  useEffect(() => {
    if (imgObject?.base64) {
      setImageUri(null);
    }
  }, [imgObject]);

  const handleOnError = () => {
    setIsLoading(true);
    if (retry <= 3) {
      setTimeout(() => {
        setRetry(retry + 1);
      }, 1000 * retry);
    } else {
      if(!imgObject?.thumbnailRegenerated) {
        postImageLoadingError(imgObject);
      }
      setIsError(true);
      setIsLoading(false);
    }
  };

  const handleOnClick = (event) => {
    onClick(recommendedWidthPercentage, recommendedHeightPercentage);
  };

  const handleWarningClick = (event) => {
    event.stopPropagation();
    postImageQualityWarning(
      recommendedWidthPixels,
      recommendedHeightPixels,
      actualWidth,
      actualHeight
    );
  };

  const handleUploadWarningClick = (event) => {
    event.stopPropagation();
    alert(
      "There was an issue with the image. Please try to re-upload."
    );
  };

  const isUploadWarning = imgObject?.uploadWarning || false;

  return (
    <OuterContainer
      ref={ref}
      key={imgObject?.thumbnailKey + retry}
      width={width}
      onClick={(event) => handleOnClick(event)}
      className={className}
      mb={mb}
    >
      <InnerContainer ratio={ratio} view={view} edited={imgObject?.editedKey}>
        {view !== "printPreview" && view !== "print" && (
          <PlaceholderInnerContainer>
            {((!isLoading && (!imgObject || isError)) || (imgObject?.loading && !imageUri && !imgObject?.base64)) && (
              <StyledCameraIcon />
            )}
          </PlaceholderInnerContainer>
        )}
        {view === "preview" && (imgObject?.uploading || imgObject?.loading || isLoading) && (
          <UploadSpinnerContainer>
            <UploadSpinnerIcon />
          </UploadSpinnerContainer>
        )}
        {(imageUri || imgObject?.base64) && (
          <>
            <Img
              src={imgObject?.base64 ? `data:image/jpeg;base64, ${imgObject?.base64}` : imageUri}
              visible={!isError}
              edited={imgObject?.editedKey}
              onLoad={() => {
                setIsLoading(false);
                setIsError(false);
              }}
              onError={() => handleOnError()}
              width={imgObject?.width}
              height={imgObject?.height}
              ratioWidth={ratioWidth}
              ratioHeight={ratioHeight}
            />
            {(view === "preview" || view === "printPreview") && (
              <>
                {(isUploadWarning || (isError && imgObject?.thumbnailRegenerated)) && (
                  <WarningContainer
                    onClick={(event) => handleUploadWarningClick(event)}
                    zIndex={11000}
                  >
                    <svg
                      width="9vw"
                      height="9vw"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.1029 7.75C13.8349 4.75 18.1651 4.75 19.8971 7.75L25.0933 16.75C26.8253 19.75 24.6603 23.5 21.1962 23.5H10.8038C7.33974 23.5 5.17468 19.75 6.90673 16.75L12.1029 7.75Z"
                        fill="#f2545b"
                        stroke="white"
                      />
                      <path
                        d="M16.9783 10.0928C16.9783 10.2744 16.9725 10.4385 16.9608 10.585C16.949 10.7314 16.9256 10.9629 16.8904 11.2793L16.2049 17.0361H15.4139L14.7283 11.2793C14.6697 10.7637 14.6404 10.3682 14.6404 10.0928C14.6404 9.64746 14.7576 9.31934 14.992 9.1084C15.2264 8.89746 15.493 8.79199 15.7918 8.79199C16.1317 8.79199 16.4129 8.90039 16.6356 9.11719C16.8641 9.32812 16.9783 9.65332 16.9783 10.0928ZM17.1893 19.9189C17.1893 20.3057 17.0545 20.6309 16.785 20.8945C16.5154 21.1582 16.1903 21.29 15.8094 21.29C15.4344 21.29 15.1121 21.1582 14.8426 20.8945C14.5731 20.6309 14.4383 20.3057 14.4383 19.9189C14.4383 19.5322 14.5701 19.2041 14.8338 18.9346C15.0975 18.665 15.4227 18.5303 15.8094 18.5303C16.1961 18.5303 16.5213 18.665 16.785 18.9346C17.0545 19.1982 17.1893 19.5264 17.1893 19.9189Z"
                        fill="white"
                      />
                    </svg>
                  </WarningContainer>
                )}
              </>
            )}
            {view === "preview" && isImageQualityWarning && (
              <WarningContainer onClick={(event) => handleWarningClick(event)} zIndex={10000}>
                <svg
                  width="9vw"
                  height="9vw"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.1029 7.75C13.8349 4.75 18.1651 4.75 19.8971 7.75L25.0933 16.75C26.8253 19.75 24.6603 23.5 21.1962 23.5H10.8038C7.33974 23.5 5.17468 19.75 6.90673 16.75L12.1029 7.75Z"
                    fill="#D6A969"
                    stroke="white"
                  />
                  <path
                    d="M16.9783 10.0928C16.9783 10.2744 16.9725 10.4385 16.9608 10.585C16.949 10.7314 16.9256 10.9629 16.8904 11.2793L16.2049 17.0361H15.4139L14.7283 11.2793C14.6697 10.7637 14.6404 10.3682 14.6404 10.0928C14.6404 9.64746 14.7576 9.31934 14.992 9.1084C15.2264 8.89746 15.493 8.79199 15.7918 8.79199C16.1317 8.79199 16.4129 8.90039 16.6356 9.11719C16.8641 9.32812 16.9783 9.65332 16.9783 10.0928ZM17.1893 19.9189C17.1893 20.3057 17.0545 20.6309 16.785 20.8945C16.5154 21.1582 16.1903 21.29 15.8094 21.29C15.4344 21.29 15.1121 21.1582 14.8426 20.8945C14.5731 20.6309 14.4383 20.3057 14.4383 19.9189C14.4383 19.5322 14.5701 19.2041 14.8338 18.9346C15.0975 18.665 15.4227 18.5303 15.8094 18.5303C16.1961 18.5303 16.5213 18.665 16.785 18.9346C17.0545 19.1982 17.1893 19.5264 17.1893 19.9189Z"
                    fill="white"
                  />
                </svg>
              </WarningContainer>
            )}
          </>
        )}
      </InnerContainer>
    </OuterContainer>
  );
};
export default Photo;
