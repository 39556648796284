import React, { useCallback } from "react";
import styled from "styled-components";
import Photo from "../components/Photo";
import { postMessage } from "../utils/postmessage";
import { useOverflowDetector } from "react-detectable-overflow";
import Heading from "../components/Heading";
import Story from "../components/Story";

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  height: 90vw;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const PhotosContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const HeadingBottomContainer = styled.div`
  position: absolute;
  bottom: 2.5vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const HeadingTopContainer = styled.div`
  margin-bottom: 2.5vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const PhotoContainer = styled.div`
  ${(props) =>
    props.items &&
    `
    width: calc((90vw - (2.5vw * ${props.items.length - 1})) / ${props.items.length});
  `}
`;

const StoryCenterBottomContainer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 21.25vw;
`;

const StoryCenter = styled(Story)`
  ${(props) => !props.children && `height: 21.25vw;`}
  width: 50vw;
`;

const PhotosHorizontal = ({ customQuestions, margin, header, handleOverflow = () => null }) => {
  const { items } = customQuestions;

  const { ref } = useOverflowDetector({
    onChange: useCallback(
      (overflow) => {
        handleOverflow(overflow);
      },
      [handleOverflow]
    )
  });

  items.sort((a, b) => (a.position > b.position ? 1 : -1));

  return (
    <>
      <OuterContainer margin={margin} ref={ref}>
        {items?.[0]?.type === "title" && (
          <HeadingTopContainer>
            <Heading.H2 tac onClick={() => postMessage(items?.[0])}>
              {items?.[0]?.answers?.items?.[0]?.text || items?.[0]?.placeholder}
            </Heading.H2>
          </HeadingTopContainer>
        )}
        <PhotosContainer>
          {items
            .filter((question) => question?.type === "image")
            .map((question, index, array) => (
              <PhotoContainer items={array} key={index}>
                <Photo
                  imgObject={question?.answers?.items?.[0]?.image || null}
                  loading={question?.answers?.items?.[0]?.image?.loading || null}
                  ratio={question?.ratio}
                  onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
                    postMessage({
                      ...question,
                      recommendedWidthPercentage,
                      recommendedHeightPercentage
                    })
                  }
                />
              </PhotoContainer>
            ))}
        </PhotosContainer>
        {items?.[items?.length - 1].type === "title" && (
          <HeadingBottomContainer>
            <Heading.H1 tac onClick={() => postMessage(items?.[items?.length - 1])}>
              {items[items.length - 1]?.answers?.items?.[0]?.text ||
                items[items.length - 1]?.placeholder}
            </Heading.H1>
          </HeadingBottomContainer>
        )}
        {items?.[items?.length - 1].type === "story-center" && (
          <StoryCenterBottomContainer>
            <StoryCenter tac onClick={() => postMessage(items?.[items?.length - 1])}>
              {items?.[items?.length - 1]?.answers?.items?.[0]?.text ||
                items?.[items?.length - 1]?.placeholder}
            </StoryCenter>
          </StoryCenterBottomContainer>
        )}
      </OuterContainer>
    </>
  );
};

export default PhotosHorizontal;
