import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Photo from "../components/Photo";
import Story from "../components/Story";
import Heading from "../components/Heading";
import { postMessage } from "../utils/postmessage";
import { useOverflowDetector } from "react-detectable-overflow";

const OuterContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 43.75vw);
  grid-template-rows: repeat(2, 43.75vw);
  grid-column-gap: 2.5vw;
  grid-row-gap: 2.5vw;
  width: 90vw;
  height: 90vw;
`;
const InnerContainer = styled.div`
  ${(props) => [0, 1].includes(props.position) && "align-self: end;"}
  ${(props) => [2, 3].includes(props.position) && "align-self: start;"}
  ${(props) => [0, 2].includes(props.position) && "justify-self: end;"}
  ${(props) => [1, 3].includes(props.position) && "justify-self: start;"}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(
    43.75vw * ${(props) => Math.min(1, props.ratio.split(":")[0] / props.ratio.split(":")[1])}
  );
  height: calc(
    43.75vw * ${(props) => Math.min(1, props.ratio.split(":")[1] / props.ratio.split(":")[0])}
  );
  order: ${(props) => props.position || 0};
`;

const StoryContainer = styled.div`
  width: 100%;
  max-height: 100%;
  ${(props) => !props.text && "flex-grow: 1;"}
`;

const TextComponent = ({ question, index, array, handleOverflow }) => {
  const { ref } = useOverflowDetector({
    onChange: useCallback(
      (overflow) => {
        handleOverflow(overflow);
      },
      [handleOverflow]
    )
  });

  return (
    <InnerContainer
      key={question?.position}
      position={question?.position}
      ratio={question?.ratio || "1:1"}
      ref={ref}
    >
      <Heading.H4 mb={2.5} mt={2.5} tac onClick={() => postMessage(question)}>
        {question?.answers?.items?.[0]?.text || question?.placeholder}
      </Heading.H4>
      <StoryContainer text={array?.[index + 1]?.answers?.items?.[0]?.text}>
        <Story onClick={() => postMessage(array?.[index + 1])}>
          {array?.[index + 1]?.answers?.items?.[0]?.text}
        </Story>
      </StoryContainer>
    </InnerContainer>
  );
};

const FourGrid = ({ customQuestions, handleOverflow = () => null }) => {
  const { items } = customQuestions;

  items.sort((a, b) => (a.position > b.position ? 1 : -1));

  const [overflowArray, setOverflowArray] = useState(items || []);

  const updateOverflowArray = (id, overflow) => {
    setOverflowArray((array) => {
      return array.map((item) => {
        if (item.id === id) {
          return { ...item, overflow };
        } else {
          return item;
        }
      });
    });
  };

  useEffect(() => {
    if (overflowArray?.length) {
      if (overflowArray.some((item) => item?.overflow)) {
        handleOverflow(true);
      } else {
        handleOverflow(false);
      }
    }
  }, [handleOverflow, overflowArray]);

  return (
    <OuterContainer>
      {items.map((question, index, array) => (
        <React.Fragment key={index}>
          {question?.type === "image" && (
            <InnerContainer
              key={question?.position}
              position={question?.position}
              ratio={question?.ratio || "1:1"}
            >
              <Photo
                imgObject={question?.answers?.items?.[0]?.image || null}
                loading={question?.answers?.items?.[0]?.image?.loading || null}
                ratio={question?.ratio}
                onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
                  postMessage({
                    ...question,
                    recommendedWidthPercentage,
                    recommendedHeightPercentage
                  })
                }
              />
            </InnerContainer>
          )}
          {question?.type === "subtitle" && (
            <TextComponent
              question={question}
              index={index}
              array={array}
              handleOverflow={(overflow) => updateOverflowArray(question?.id, overflow)}
            />
          )}
        </React.Fragment>
      ))}
    </OuterContainer>
  );
};

export default FourGrid;
