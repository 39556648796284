import React from "react";
import styled from "styled-components";
import Photo from "../components/Photo";
import { postMessage } from "../utils/postmessage";
import Text from "../components/Text";

const OuterContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 90vw;
  height: 90vw;
  position: relative;
  justify-content: center;
  align-items: center;
`;
const StyledPhoto = styled(Photo)`
  width: calc(
    92.5vw /
      (
        (${(props) => props.ratio.split(":")[0]} / ${(props) => props.ratio.split(":")[1]}) *
          ${(props) => props.ratio.split(":")[0]} + ${(props) => props.ratio.split(":")[1]}
      ) *
      (
        (${(props) => props.ratio.split(":")[0]} / ${(props) => props.ratio.split(":")[1]}) *
          ${(props) => props.ratio.split(":")[0]}
      )
  );
  margin-left: ${(props) => props.marginLeft || "0"};
`;

const VerticalLine = styled.div`
  height: 25vw;
  width: 1px;
  box-sizing: border-box;
  border-left: 1px solid black;
  position: absolute;
  z-index: -1;
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  top: ${(props) => props.top};
`;

const StyledSubtitle = styled(Text)`
  position: absolute;
  z-index: 10000;
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  top: ${(props) => props.top};
`;
// Arrays containing configuration for each position
const PHOTO_CONFIGS = [{ marginLeft: "0" }, { marginLeft: "2.5vw" }, { marginLeft: "2.5vw" }];

const VERTICALLINE_CONFIGS = {
  "timeline-alt-1": [
    { left: "10vw", top: "15vw" },
    { right: "47.875vw", bottom: "16vw" },
    { right: "10vw", top: "15vw" }
  ],
  "timeline-alt-2": [
    { left: "10vw", bottom: "16vw" },
    { right: "47.875vw", top: "15vw" },
    { right: "10vw", bottom: "16vw" }
  ]
};

const SUBTITLE_CONFIGS = {
  "timeline-alt-1": [
    { left: "10vw", top: "12vw" },
    { right: "47.875vw", bottom: "13vw" },
    { right: "10vw", top: "12vw" }
  ],
  "timeline-alt-2": [
    { left: "10vw", bottom: "13vw" },
    { left: "42vw", top: "12vw" },
    { right: "10vw", bottom: "13vw" }
  ]
};

const TimeLineAlt = ({ customQuestions, template }) => {
  customQuestions.items.sort((a, b) => (a.position > b.position ? 1 : -1));
  return (
    <OuterContainer>
      {customQuestions.items.map((question, index) => (
        <>
          {question?.type === "image" && (
            <>
              <StyledPhoto
                imgObject={question?.answers?.items?.[0]?.image || null}
                loading={question?.answers?.items?.[0]?.image?.loading || null}
                ratio={question?.ratio}
                {...PHOTO_CONFIGS[index]}
                onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
                  postMessage({
                    ...question,
                    recommendedWidthPercentage,
                    recommendedHeightPercentage
                  })
                }
              />
              <VerticalLine {...VERTICALLINE_CONFIGS[template][index]} />
            </>
          )}
          {(template === "timeline-alt-1" || template === "timeline-alt-2") && (
            <>
              {question?.type === "text" && (
                <StyledSubtitle
                  subtitle
                  tac
                  onClick={() => postMessage(question)}
                  {...SUBTITLE_CONFIGS[template][index - 3]}
                >
                  {question?.answers?.items?.[0]?.text || question?.placeholder}
                </StyledSubtitle>
              )}
            </>
          )}
        </>
      ))}
    </OuterContainer>
  );
};

export default TimeLineAlt;
