import React, { useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Photo from "../components/Photo";
import Heading from "../components/Heading";
import Story from "../components/Story";
import { postMessage } from "../utils/postmessage";
import { useOverflowDetector } from "react-detectable-overflow";

const OuterContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 90vw;
  height: 90vw;
  justify-content: space-between;
  align-items: center;
`;

const InnerContainer = styled.div`
  order: ${(props) => props.position || 0};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  margin-bottom: 2.5vw;
  ${(props) =>
    props.layout === "top-left" &&
    css`
      ${(props) =>
        !props.position &&
        `width: calc(87.5vw * (3/5));
    `}
      ${(props) =>
        props.position === 1 &&
        `width: calc(87.5vw * (2/5));
        `}
    ${(props) =>
        props.position === 2 &&
        `width: calc(87.5vw * (3/5));
        `}
    ${(props) =>
        props.position === 3 &&
        `width: calc(87.5vw * (2/5));
        `}
    `}
  ${(props) =>
    props.layout === "bottom-right" &&
    css`
      ${(props) =>
        (props.position === 0 || props.position === 3 || props.position === 4) &&
        `width: calc(87.5vw * (2/5));
    `}
      ${(props) =>
        (props.position === 0 || props.position === 1) &&
        `height: calc(87.5vw * (2/5));
    `}
      ${(props) =>
        (props.position === 3 || props.position === 5) &&
        `height: calc(87.5vw * (3/5));
    `}
      ${(props) =>
        (props.position === 1 ||
          props.position === 2 ||
          props.position === 5 ||
          props.position === 6) &&
        `width: calc(87.5vw * (3/5));
        `}
    `}
`;
const StyledPhoto = styled(Photo)`
  height: 100%;
  width: 100%;
`;

const StoryContainer = styled.div`
  width: 100%;
  max-height: 100%;
  ${(props) => !props.text && "flex-grow: 1;"}
`;

const TextComponent = ({ question, index, array, layout, handleOverflow }) => {
  const { ref } = useOverflowDetector({
    onChange: useCallback(
      (overflow) => {
        handleOverflow(overflow);
      },
      [handleOverflow]
    )
  });

  return (
    <InnerContainer
      key={question?.position}
      position={question?.position}
      layout={layout}
      ref={ref}
    >
      {question?.type === "subtitle" && (
        <Heading.H4 tac mb={2.5} mt={2.5} onClick={() => postMessage(question)}>
          {question?.answers?.items?.[0]?.text || question?.placeholder}
        </Heading.H4>
      )}
      {question?.type === "title" && (
        <Heading.H2 tac onClick={() => postMessage(question)}>
          {question?.answers?.items?.[0]?.text || question?.placeholder}
        </Heading.H2>
      )}
      <StoryContainer text={array?.[index + 1]?.answers?.items?.[0]?.text}>
        <Story onClick={() => postMessage(array?.[index + 1])}>
          {array?.[index + 1]?.answers?.items?.[0]?.text}
        </Story>
      </StoryContainer>
    </InnerContainer>
  );
};

const FourGridAssymetric = ({ customQuestions, layout, handleOverflow = () => null }) => {
  const { items } = customQuestions;

  items.sort((a, b) => (a.position > b.position ? 1 : -1));

  const [overflowArray, setOverflowArray] = useState(items || []);

  const updateOverflowArray = (id, overflow) => {
    setOverflowArray((array) => {
      return array.map((item) => {
        if (item.id === id) {
          return { ...item, overflow };
        } else {
          return item;
        }
      });
    });
  };

  useEffect(() => {
    if (overflowArray?.length) {
      if (overflowArray.some((item) => item?.overflow)) {
        handleOverflow(true);
      } else {
        handleOverflow(false);
      }
    }
  }, [handleOverflow, overflowArray]);

  return (
    <OuterContainer>
      {items.map((question, index, array) => (
        <React.Fragment key={index}>
          {question?.type === "image" && (
            <InnerContainer key={question?.position} position={question?.position} layout={layout}>
              <StyledPhoto
                position={question?.position}
                imgObject={question?.answers?.items?.[0]?.image || null}
                loading={question?.answers?.items?.[0]?.image?.loading || null}
                ratio={question?.ratio}
                onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
                  postMessage({
                    ...question,
                    recommendedWidthPercentage,
                    recommendedHeightPercentage
                  })
                }
              />
            </InnerContainer>
          )}
          {["title", "subtitle"].includes(question?.type) && (
            <TextComponent
              question={question}
              index={index}
              array={array}
              layout={layout}
              handleOverflow={(overflow) => updateOverflowArray(question?.id, overflow)}
            />
          )}
        </React.Fragment>
      ))}
    </OuterContainer>
  );
};

export default FourGridAssymetric;
