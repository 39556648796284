import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Photo from "../components/Photo";
import Story from "../components/Story";
import Heading from "../components/Heading";
import { postMessage } from "../utils/postmessage";
import { useOverflowDetector } from "react-detectable-overflow";

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 90vw;
  height: 90vw;
  justify-content: center;
  align-items: center;
`;

const StyledPhoto = styled(Photo)`
  width: ${(props) => {
    if (props.largePhotos) {
      return "60vw";
    }
    if (props.ratio === "1:1") {
      return "calc(87.5vw / 5 * 2)";
    }
    if (props.ratio === "3:2") {
      return "calc(87.5vw / 5 * 3)";
    }
  }};
  margin-right: 2.5vw;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.largePhotos ? "27.5vw" : "calc(87.5vw / 5 * 2)")};
  height: calc(${(props) => (props.largePhotos ? "60vw" : "50vw")} * (2 / 3));
  justify-content: center;
  align-items: center;
  margin-right: 2.5vw;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${(props) =>
    props.mb &&
    `
    margin-bottom: ${props.mb}vw;
    `}
  margin-right: -2.5vw;
`;

const StoryContainer = styled.div`
  width: 100%;
  max-height: 100%;
  ${(props) => !props.text && "flex-grow: 1;"}
`;

const TextComponent = ({ question, index, array, largePhotos, handleOverflow }) => {
  const { ref } = useOverflowDetector({
    onChange: useCallback(
      (overflow) => {
        handleOverflow(overflow);
      },
      [handleOverflow]
    )
  });

  return (
    <TextContainer largePhotos={largePhotos} ref={ref}>
      {question?.type === "title" && (
        <Heading.H2 tac onClick={() => postMessage(question)}>
          {question?.answers?.items?.[0]?.text || question?.placeholder}
        </Heading.H2>
      )}
      {question?.type === "subtitle" && (
        <>
          <Heading.H4 tac mb={2.5} mt={2.5} onClick={() => postMessage(question)}>
            {question?.answers?.items?.[0]?.text || question?.placeholder}
          </Heading.H4>
          <StoryContainer text={array[index + 1]?.answers?.items?.[0]?.text}>
            <Story onClick={() => postMessage(array[index + 1])}>
              {array[index + 1]?.answers?.items?.[0]?.text}
            </Story>
          </StoryContainer>
        </>
      )}
    </TextContainer>
  );
};
const RenderRow = ({ items, largePhotos, updateOverflowArray }) => (
  <Row mb={2.5}>
    {items.map((question, index, array) => (
      <React.Fragment key={index}>
        {(question?.type === "title" || question?.type === "subtitle") && (
          <TextComponent
            question={question}
            index={index}
            array={array}
            largePhotos={largePhotos}
            handleOverflow={(overflow) => updateOverflowArray(question?.id, overflow)}
          />
        )}
        {question?.type === "image" && (
          <StyledPhoto
            imgObject={question?.answers?.items?.[0]?.image || null}
            loading={question?.answers?.items?.[0]?.image?.loading || null}
            ratio={question?.ratio}
            onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
              postMessage({
                ...question,
                recommendedWidthPercentage,
                recommendedHeightPercentage
              })
            }
            largePhotos={largePhotos}
          />
        )}
      </React.Fragment>
    ))}
  </Row>
);

const FourGridOffset = ({ customQuestions, largePhotos, handleOverflow = () => null }) => {
  const { items } = customQuestions;
  items.sort((a, b) => (a.position > b.position ? 1 : -1));
  const [overflowArray, setOverflowArray] = useState([...items]);

  const updateOverflowArray = (id, overflow) => {
    setOverflowArray((array) => {
      return array.map((item) => {
        if (item.id === id) {
          return { ...item, overflow };
        } else {
          return item;
        }
      });
    });
  };

  useEffect(() => {
    if (overflowArray.some((item) => item?.overflow)) {
      handleOverflow(true);
    } else {
      handleOverflow(false);
    }
  }, [handleOverflow, overflowArray]);

  const halfItemsLength = items.length / 2;
  return (
    <OuterContainer>
      <RenderRow
        items={items.filter((item) => item.position < halfItemsLength)}
        largePhotos={largePhotos}
        updateOverflowArray={updateOverflowArray}
      />
      <RenderRow
        items={items.filter((item) => item.position >= halfItemsLength)}
        largePhotos={largePhotos}
        updateOverflowArray={updateOverflowArray}
      />
    </OuterContainer>
  );
};

export default FourGridOffset;
