import React, { useCallback } from "react";
import styled from "styled-components";
import { useOverflowDetector } from "react-detectable-overflow";
import Photo from "../components/Photo";
import Story from "../components/Story";
import Heading from "../components/Heading";
import { postMessage } from "../utils/postmessage";

const Container = styled.div`
  height: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${(props) => (props.center ? "width: 60vw;" : "width: 80vw;")}
  height: 80vw;
`;

const InnerContainer = styled.div`
  order: ${(props) => props.position || 0};
  ${(props) => props.position === 2 && "margin-top:10vw;"}
`;

const StyledStory = styled(Story)`
  order: ${(props) => props.position || 0};
  ${(props) => (props.center ? "width: 60vw;" : "width: 80vw;")}
`;

const StyledPhoto = styled(Photo)`
  order: ${(props) => props.position || 0};
`;

const StoryPage = ({ customQuestions, header, center, handleOverflow = () => null }) => {
  const { items } = customQuestions;

  const { ref } = useOverflowDetector({
    onChange: useCallback(
      (overflow) => {
        handleOverflow(overflow);
      },
      [handleOverflow]
    )
  });

  items.sort((a, b) => (a.position > b.position ? 1 : -1));

  return (
    <Container ref={ref}>
      <OuterContainer center={center}>
        {items.map((question, index) => (
          <React.Fragment key={index}>
            {question?.type === "image" && (
              <StyledPhoto
                mb={2.5}
                imgObject={question?.answers?.items?.[0]?.image || null}
                loading={question?.answers?.items?.[0]?.image?.loading || null}
                ratio={question?.ratio}
                onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
                  postMessage({
                    ...question,
                    recommendedWidthPercentage,
                    recommendedHeightPercentage
                  })
                }
              />
            )}
            {question?.type === "title" && (
              <InnerContainer key={question?.id} position={question?.position}>
                {header !== "h2" ? (
                  <Heading.H3 tac mb={2.5} onClick={() => postMessage(question)}>
                    {question?.answers?.items?.[0]?.text || question?.placeholder}
                  </Heading.H3>
                ) : (
                  <Heading.H2 tac mb={2.5} onClick={() => postMessage(question)}>
                    {question?.answers?.items?.[0]?.text || question?.placeholder}
                  </Heading.H2>
                )}
              </InnerContainer>
            )}
            {question?.type === "subtitle" && (
              <InnerContainer key={question?.id} position={question?.position}>
                <Heading.H4 tac mb={2.5} onClick={() => postMessage(question)}>
                  {question?.answers?.items?.[0]?.text || question?.placeholder}
                </Heading.H4>
              </InnerContainer>
            )}
            {(question?.type === "story" || question?.type === "story-center") && (
              <StyledStory
                tac={center || question?.type === "story-center"}
                center={center || question?.type === "story-center"}
                onClick={() => postMessage(question)}
                position={question?.position}
              >
                {question?.answers?.items?.[0]?.text}
              </StyledStory>
            )}
          </React.Fragment>
        ))}
      </OuterContainer>
    </Container>
  );
};
export default StoryPage;
