import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { EyeSlash } from "@styled-icons/fa-regular";
import { isAndroid } from "react-device-detect";
import { postReady } from "../utils/postmessage";
import params from "../utils/params";

import Templates from "../templates";

// 96 is used as Puppeteer is having a weird 0.75 scale issue. So 72dpi / 0.75 = 96
const Container = styled.div`
  ${(props) =>
    props.view === "print" &&
    `
    @media print {
      page-break-after: always;
    }
    @page {
      size: ${(305 / 25.4) * 96}px ${(305 / 25.4) * 96}px;
    }
    * {
      -webkit-print-color-adjust: exact;
    }
  `}
`;

const Hidden = styled.div`
  position: fixed;
  z-index: 1000;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  width: 100vw;
  height: 100vw;
  justify-content: center;
  align-items: center;
`;

const Page = ({ pageId: pageIdProps }) => {
  const userId = params?.user;
  const bookId = params?.book;
  const pageId = pageIdProps || params?.page;
  const view = params?.view;
  const [page, setPage] = useState();

  useEffect(() => {
    postReady();
  }, []);

  useEffect(() => {
    const receiver = isAndroid ? document : window;
    const messageHandler = async (event) => {
      if (!event.origin) {
        setPage(JSON.parse(event.data));
      }
    };

    receiver.addEventListener("message", messageHandler);

    return () => {
      receiver.removeEventListener("message", messageHandler);
    };
  }, []);

  useEffect(() => {
    const fetchPage = async () => {
      try {
        const getPage = await fetch(
          `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/user/${userId}/book/${bookId}/page/${pageId}`,
          {}
        );
        const json = await getPage.json();
        setPage(json);
      } catch (e) {
        // alert(e);
        console.log(e.message);
      }
    };

    if (bookId && pageId && userId && view === "print") {
      fetchPage();
    }
  }, [bookId, pageId, userId, view]);

  return (
    <Container view={view}>
      {page && (
        <>
          {page?.hide && (
            <Hidden>
              <EyeSlash height="25%" color="white" />
            </Hidden>
          )}
          <Templates user={userId} page={page} />
        </>
      )}
    </Container>
  );
};

export default Page;
