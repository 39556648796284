import React from "react";
import styled from "styled-components";
import Photo from "../components/Photo";
import { postMessage } from "../utils/postmessage";

const OuterContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 90vw;
  justify-content: space-between;
  align-items: center;
`;
const LeftContainer = styled.div`
  width: calc(
    ((90vw - ${(props) => props.gutter}vw) + (${(props) => props.gutter}vw * 0.75)) / (4.25 / 2)
  );
  margin-right: ${(props) => props.gutter}vw;
`;
const RightContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const StyledPhoto = styled(Photo)`
  width: 100%;
  ${(props) =>
    props.mb &&
    `
      margin-bottom: ${props.mb}vw;
      `}
`;

const ThreeGrid3 = ({ customQuestions }) => {
  const { items } = customQuestions;
  const gutter = 2.5;
  items.sort((a, b) => (a.position > b.position ? 1 : -1));
  return (
    <OuterContainer>
      <LeftContainer gutter={gutter}>
        <StyledPhoto
          position={items?.[0]?.position}
          imgObject={items?.[0]?.answers?.items?.[0]?.image || null}
          loading={items?.[0]?.answers?.items?.[0]?.image?.loading || null}
          ratio={items?.[0]?.ratio}
          onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
            postMessage({
              ...items?.[0],
              recommendedWidthPercentage,
              recommendedHeightPercentage
            })
          }
        />
      </LeftContainer>
      <RightContainer>
        <StyledPhoto
          position={items[1].position}
          imgObject={items[1]?.answers?.items?.[0]?.image || null}
          loading={items[1]?.answers?.items?.[0]?.image?.loading || null}
          ratio={items[1]?.ratio}
          onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
            postMessage({
              ...items[1],
              recommendedWidthPercentage,
              recommendedHeightPercentage
            })
          }
          mb={gutter}
        />
        <StyledPhoto
          position={items[2].position}
          imgObject={items[2]?.answers?.items?.[0]?.image || null}
          loading={items[2]?.answers?.items?.[0]?.image?.loading || null}
          ratio={items[2]?.ratio}
          onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
            postMessage({
              ...items[2],
              recommendedWidthPercentage,
              recommendedHeightPercentage
            })
          }
        />
      </RightContainer>
    </OuterContainer>
  );
};

export default ThreeGrid3;
