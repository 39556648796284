import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BackPage from "../components/BackPage";
import params from "../utils/params";

// 96 is used as Puppeteer is having a weird 0.75 scale issue. So 72dpi / 0.75 = 96
const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media print {
    page-break-after: always;
  }
  height: ${(props) => ((props.pageHeight + 46) / 25.4) * 96}px;
  width: ${(props) => ((props.pageWidth + props.spineWidth + 46) / 25.4) * 96}px;

  ${(props) =>
    props.view === "print" &&
    `
    @page {
      size: ${((props.pageWidth + props.spineWidth + 46) / 25.4) * 96}px ${
      ((props.pageHeight + 46) / 25.4) * 96
    }px;
    }
    * {
      -webkit-print-color-adjust: exact;
    }
  `}
`;
const Spine = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.width &&
    `
    width: ${(props.width / 25.4) * 96}px;
  `}
  ${(props) =>
    props.height &&
    `
    height:${(props.height / 25.4) * 96}px;
  `}
`;

const SpineText = styled.div`
  transform: rotate(90deg);
  font-family: "Quicksand", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-weight: 100;
  text-transform: uppercase;
  font-size: 1.75vh;
  ${(props) =>
    props.width &&
    `
    line-height: ${(props.width / 25.4) * 96}px;
  `}
  white-space: nowrap;
  text-align: center;
`;

const BackPageContainer = styled.div`
  height: ${(props) => (props.pageHeight / 25.4) * 96}px;
  width: ${(props) => (props.pageWidth / 25.4) * 96}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Cover = () => {
  const { view, user: userId, book: bookId, identity } = params;

  const [frontPage, setFrontPage] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [spineText, setSpineText] = useState("");

  const getSpineWidth = (pageCount) => {
    if (pageCount >= 20 && pageCount <= 50) {
      return 6.76;
    }
    if (pageCount >= 51 && pageCount <= 100) {
      return 11.25;
    }
    if (pageCount >= 101 && pageCount <= 150) {
      return 15.75;
    }
    if (pageCount >= 151 && pageCount <= 200) {
      return 20.24;
    }
    if (pageCount >= 201 && pageCount <= 250) {
      return 24.74;
    }
    if (pageCount >= 251 && pageCount <= 300) {
      return 29.24;
    }
    return 6.76;
  };

  useEffect(() => {
    const fetchBook = async () => {
      try {
        const getBook = await fetch(
          `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/user/${userId}/book/${bookId}`,
          {}
        );
        const json = await getBook.json();
        setPageCount(json?.customPages?.items?.filter((page) => !page.hide).length - 1);
        const _frontPage = json?.customPages?.items?.find((item) => item.number === 0);
        setFrontPage(_frontPage);
        const _spineText =
          _frontPage?.customQuestions?.items?.find((item) => item.position === 0)?.answers
            ?.items?.[0]?.text ||
          _frontPage?.customQuestions?.items?.find((item) => item.position === 0)?.placeholder;
        setSpineText(_spineText);
      } catch (e) {
        // alert(e);
        console.log(e.message);
      }
    };

    if (view === "print") {
      fetchBook();
    }
  }, [bookId, userId, view]);

  const pageWidth = 612 / 2;
  const pageHeight = 305;
  const spineWidth = getSpineWidth(pageCount);
  return (
    <Container
      view={view}
      pageHeight={pageHeight}
      pageWidth={pageWidth * 2}
      spineWidth={spineWidth}
    >
      <>
        <BackPageContainer pageHeight={pageHeight} pageWidth={pageWidth} spineWidth={spineWidth}>
          <BackPage width={pageWidth} height={pageHeight} />
        </BackPageContainer>
        {frontPage && (
          <>
            <Spine text={frontPage} width={getSpineWidth(pageCount)} height={pageHeight}>
              <SpineText width={getSpineWidth(pageCount)}>{spineText}</SpineText>
            </Spine>
            <iframe
              src={`${window.location.origin}/page?user=${userId}&book=${bookId}&page=${frontPage?.id}&identity=${identity}&view=${view}`}
              height={`${(pageHeight / 25.4) * 96}px`}
              width={`${(pageWidth / 25.4) * 96}px`}
              title="Front Page"
            ></iframe>
          </>
        )}
      </>
    </Container>
  );
};

export default Cover;
