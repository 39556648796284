import React from "react";
import styled from "styled-components";
import logo from "../images/babynotebook-logo.svg";

const BackPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: ${(props) => !Number.isInteger(props.height) && props.height.includes("vw") ? props.height: `${(props.height / 25.4) * 96}px`};
  width: ${(props) => !Number.isInteger(props.height) && props.width.includes("vw") ? props.width: `${(props.width / 25.4) * 96}px`};
`;

const LogoContainer = styled.div`
  ${(props) =>
    !Number.isInteger(props.height) && props.height.includes("vw")
      ? `height: calc(${props.height} * 0.15); margin-bottom: calc(${props.height} * 0.05);`
      : `height: ${(props.height / 25.4) * 96 * 0.15}px; margin-bottom: ${
          (props.height / 25.4) * 96 * 0.05
        }px;`}
  width: 100%;
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    height: auto;
  }
`;

const BackPageComponent = ({ width, height }) => {
  return (
    <BackPage width={width} height={height}>
      <LogoContainer height={height}>
        <img src={logo} alt="Baby Notebook Logo" />
      </LogoContainer>
    </BackPage>
  );
};

export default BackPageComponent;
