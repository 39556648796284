import React, { useCallback } from "react";
import { useOverflowDetector } from "react-detectable-overflow";
import { postMessage } from "../utils/postmessage";
import styled from "styled-components";
import Text from "../components/Text";
import Heading from "../components/Heading";
import params from "../utils/params";

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 90vw;
  width: 90vw;
  justify-content: center;
  align-items: center;
  position: relative;
`;
const Columns = styled.div`
  display: flex;
  flex-direction: row;
  width: 90vw;
  margin-top: 15vw;
  justify-content: center;
  height: calc(6 * (5vw + 2.45vw));
  z-index: 1000;
`;
const DateColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 12.5vw;
  margin-right: 5vw;
`;
const AgeColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 12.5vw;
  margin-right: 5vw;
`;
const WeightColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 12.5vw;
  margin-right: 5vw;
`;
const HeightColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 12.5vw;
`;
const InnerContainer = styled.div`
  display: flex;
  flex: 0 1 auto;
  padding-top: 2.5vw;
  padding-bottom: 2.5vw;
  height: 2.1vw;
  & > div {
    height: 2.1vw;
  }
`;
const HeadingContainer = styled.div`
  position: absolute;
  top: 10vw;
  display: flex;
  flex-grow: 1;
  width: 90vw;
`;

const StyledText = styled(Text)`
  display: flex;
  width: 100%;
`;

const StyledHeading = styled(Heading.H4)`
  display: flex;
  flex-grow: 1;
`;

const ChartItem = ({ question }) => (
  <InnerContainer onClick={() => postMessage(question)}>
    {question?.type === "subtitle" && (
      <StyledHeading bold>
        {question?.answers?.items?.[0]?.text || question?.placeholder}
      </StyledHeading>
    )}
    {question?.type === "text" && (
      <StyledText subtitle>
        {question?.answers?.items?.[0]?.text || question?.placeholder}
      </StyledText>
    )}
  </InnerContainer>
);

const Chart = ({ customQuestions, handleOverflow = () => null }) => {
  const { ref } = useOverflowDetector({
    onChange: useCallback(
      (overflow) => {
        handleOverflow(overflow);
      },
      [handleOverflow]
    )
  });
  const { view } = params;
  const { items } = customQuestions;
  const order = [
    0, 1, 2, 3, 4, 5, 25, 6, 7, 8, 9, 10, 11, 26, 12, 13, 14, 15, 16, 17, 27, 18, 19, 20, 21, 22,
    23, 28, 24
  ];
  items.sort((a, b) => (order.indexOf(a.position) > order.indexOf(b.position) ? 1 : -1));
  const showRow = (type, array, index) => {
    let isType = false;
    if (type === "Date" && [1, 2, 3, 4, 5, 25, 6].includes(array?.[index]?.position)) {
      isType = true;
    }
    if (type === "Age" && [7, 8, 9, 10, 11, 26, 12].includes(array?.[index]?.position)) {
      isType = true;
    }
    if (type === "Weight" && [13, 14, 15, 16, 17, 27, 18].includes(array?.[index]?.position)) {
      isType = true;
    }
    if (type === "Height" && [19, 20, 21, 22, 23, 28, 24].includes(array?.[index]?.position)) {
      isType = true;
    }
    const isPreview = view === "preview";
    const isHeader = array?.[index]?.type === "subtitle";
    const isPrint = view === "print" || view === "printPreview";
    const isTextSet =
      array?.[index]?.answers?.items?.[0]?.text ||
      array?.[index + 7]?.answers?.items?.[0]?.text ||
      array?.[index + 14]?.answers?.items?.[0]?.text ||
      array?.[index + 21]?.answers?.items?.[0]?.text ||
      array?.[index + 28]?.answers?.items?.[0]?.text ||
      array?.[index - 7]?.answers?.items?.[0]?.text ||
      array?.[index - 14]?.answers?.items?.[0]?.text ||
      array?.[index - 21]?.answers?.items?.[0]?.text ||
      array?.[index - 28]?.answers?.items?.[0]?.text;

    return isType && (isPreview || isHeader || (isPrint && isTextSet));
  };

  return (
    <OuterContainer>
      {items?.[0]?.type === "title" && (
        <HeadingContainer ref={ref}>
          <Heading.H1 tac onClick={() => postMessage(items?.[0])}>
            {items?.[0]?.answers?.items?.[0]?.text || items?.[0]?.placeholder}
          </Heading.H1>
        </HeadingContainer>
      )}
      <Columns>
        <DateColumn>
          {items.map((question, index, array) => {
            if (!showRow("Date", array, index)) return null;
            return <ChartItem key={question?.position} question={question}></ChartItem>;
          })}
        </DateColumn>
        <AgeColumn>
          {items.map((question, index, array) => {
            if (!showRow("Age", array, index)) return null;
            return <ChartItem key={question?.position} question={question}></ChartItem>;
          })}
        </AgeColumn>
        <WeightColumn>
          {items.map((question, index, array) => {
            if (!showRow("Weight", array, index)) return null;
            return <ChartItem key={question?.position} question={question}></ChartItem>;
          })}
        </WeightColumn>
        <HeightColumn>
          {items.map((question, index, array) => {
            if (!showRow("Height", array, index)) return null;
            return <ChartItem key={question?.position} question={question}></ChartItem>;
          })}
        </HeightColumn>
      </Columns>
    </OuterContainer>
  );
};

export default Chart;
