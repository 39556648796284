import React from "react";
import styled from "styled-components";
import params from "../utils/params";

const Text = styled.div`
  font-size: 1.5vw;
  line-height: 2.45vw;
  font-family: "EB Garamond", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  ${(props) =>
    props.tac &&
    `
    text-align: center;
  `}
  ${(props) =>
    props.subtitle &&
    `
    margin-bottom: 0;
    margin-top: 0;
    white-space: pre-wrap;
    font-family: "Quicksand", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 100;
    text-transform: uppercase;
    font-size: 1.75vw;
    line-height: 2.1vw;
  `}
  ${(props) =>
    !props.children &&
    props.view !== "printPreview" &&
    props.view !== "print" &&
    `
      max-width: 100%;
      width:20vw;
      height:1.96vw;
      line-height: 2.45vw;
      border-bottom: 0.3vw solid black;
      box-sizing: border-box;
    `}
`;

const TextComponent = ({ children, className, onClick, subtitle, tac }) => {
  const { view } = params;

  return (
    <Text
      key={children}
      className={className}
      onClick={onClick}
      view={view}
      subtitle={subtitle}
      tac={tac}
    >
      {/* replace new lines and old emojis like heart suit - heart suit replacement should really be fixed in Puppeteer */}
      {children
        ?.replace(/\n/g, "\r\n")
        ?.replace(/♥️/g, "❤️")
        ?.replace(/\uFFFC/g, "")}
    </Text>
  );
};

export default TextComponent;
