import React, { useCallback } from "react";
import styled from "styled-components";
import Photo from "../components/Photo";
import Story from "../components/Story";
import { postMessage } from "../utils/postmessage";
import { useOverflowDetector } from "react-detectable-overflow";
import Heading from "../components/Heading";

const OuterContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${(props) => props.margin ? `width:90vw; height:90vw;`: 'width: 100vw; heigh: 100vw;'}
`;

const HeaderContainer = styled.div`
  order: ${(props) => props.position || 0};
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  width: 90vw;
  ${(props) =>
    props.mb &&
    `
      margin-bottom: ${props.mb}vw;
    `}
  h1,
  h2 {
    width: 100%;
  }
  div {
    align-items: center;
    justify-content: center;
  }
`;
const StyledPhoto = styled(Photo)`
  order: ${(props) => props.position || 0};
`;

const StoryCenterContainer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 12.5vw;
  width: 90vw;
`;

const StoryCenter = styled(Story)`
  ${(props) => !props.children && `height: 12.5vw;`}
  width:50vw;
`;

const PhotoFullPage = ({ customQuestions, margin, header, handleOverflow = () => null }) => {
  const { items } = customQuestions;

  const { ref } = useOverflowDetector({
    onChange: useCallback(
      (overflow) => {
        handleOverflow(overflow);
      },
      [handleOverflow]
    )
  });

  items.sort((a, b) => (a.position > b.position ? 1 : -1));

  return (
    <OuterContainer margin={margin} items={items.length} ref={ref}>
      {items.map((question, index, array) => (
        <React.Fragment key={question?.id}>
          {question?.type === "image" && (
            <StyledPhoto
              imgObject={question?.answers?.items?.[0]?.image || null}
              loading={question?.answers?.items?.[0]?.image?.loading || null}
              ratio={question?.ratio}
              onClick={(recommendedWidthPercentage, recommendedHeightPercentage) =>
                postMessage({
                  ...question,
                  recommendedWidthPercentage,
                  recommendedHeightPercentage
                })
              }
              position={question?.position}
            />
          )}
          {question?.type === "title" && (
            <HeaderContainer
              mb={array.length - 1 === index ? 0 : 2.5}
              position={question?.position}
            >
              {header !== "h1" ? (
                <Heading.H2 tac onClick={() => postMessage(question)}>
                  {question?.answers?.items?.[0]?.text || question?.placeholder}
                </Heading.H2>
              ) : (
                <Heading.H1 tac onClick={() => postMessage(question)}>
                  {question?.answers?.items?.[0]?.text || question?.placeholder}
                </Heading.H1>
              )}
            </HeaderContainer>
          )}
          {question?.type === "story-center" && (
            <StoryCenterContainer>
              <StoryCenter tac onClick={() => postMessage(question)}>
                {question?.answers?.items?.[0]?.text || question?.placeholder}
              </StoryCenter>
            </StoryCenterContainer>
          )}
        </React.Fragment>
      ))}
    </OuterContainer>
  );
};

export default PhotoFullPage;
